import { memo, useCallback, useState } from "react";
import classNames from 'classnames'
import cls from './InstructionStepLiveTournament.module.scss';
import { TournamentCardImageBlock } from "@newComponents/ui/tournaments/TournamentCardImageBlock";
import { mockTournament } from "../model/tournament";
import { TournamentLiveTimer } from "@newComponents/ui/tournaments/tournamentPage/TournamentLiveTimer";
import { TournamentPrizePool } from "@newComponents/ui/tournaments/TournamentPrizePool";
import { Button } from "@newComponents/shared/Button/Button";
import { TournamentResultModal } from "@newComponents/ui/tournaments/tournamentPage/TournamentResultModal";
import { useDispatch } from "react-redux";
import { getRewardOfLearning } from "@store/services/getRewardOfLearning";

interface InstructionStepLiveTournamentProps {
  className?: string;
}

const SECONDS_IN_MINUTES = 60

export const InstructionStepLiveTournament = memo((props: InstructionStepLiveTournamentProps) => {
  const { className } = props;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isClosedInstructionText, setIsClosedInstructionText] = useState(false);
  const dispatch = useDispatch();

  const toggleWinTournament = useCallback(() => {
    setIsOpenModal(true);
  }, [])

  const handleCloseModal = useCallback(() => {
    dispatch(getRewardOfLearning());
  }, [dispatch])

  const handleCloseInstructionText = useCallback(() => {
    setIsClosedInstructionText(true);
  }, [])

  const getMockDateEnd = useCallback((newDate: Date) => new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
      newDate.getSeconds() + SECONDS_IN_MINUTES
    ), [])

  return (
    <div className={classNames(cls.InstructionStepLiveTournament, className)}>
      <div className={cls.infoBlock}>
        <TournamentCardImageBlock
          className={cls.imageBlock}
          tournament={mockTournament}
          size="small"
        />
        <div className={classNames(cls.blockWrapper, cls.timerBlock)}>
          <TournamentLiveTimer
            onWinInstructionTournament={toggleWinTournament}
            className={cls.timer}
            tournament={mockTournament}
            dateEnd={getMockDateEnd(new Date())}
          />
        </div>
        <div className={classNames(cls.blockWrapper, cls.prizeBlock)}>
          <TournamentPrizePool />
        </div>
      </div>
      {!isClosedInstructionText && (
        <div className={cls.info}>
          <h3 className={cls.title}>
            Обучение
          </h3>
          <p className={cls.description}>
            Турнир начался, когда вы выйграете, вы получите уведомление о победе.
          </p>
          <div className={cls.buttons}>
            <Button
              className={cls.button}
              theme="contained-green"
              onClick={handleCloseInstructionText}
            >
              Окей!
            </Button>
          </div>
        </div>
    )}
      {isOpenModal && (
        <TournamentResultModal
          type="winner"
          onClose={handleCloseModal}
          isInstruction
        />
      )}
    </div>
  )
})