import { memo, useCallback } from "react";
import classNames from 'classnames'
import cls from './PhoneCodesSelect.module.scss';
import {
  useFetchPhoneCodesQuery
} from "@services/phoneCodes.service";
import { BACKEND_BASE_URL } from "@constants/constants";

interface PhoneCodesSelectProps {
  onChangeCountryCode: (code: string) => void;
  onClose: () => void;
  className?: string;
}

export const PhoneCodesSelect = memo((props: PhoneCodesSelectProps) => {
  const { onClose, onChangeCountryCode, className } = props;
  const {data} = useFetchPhoneCodesQuery();

  const handleChangeCountryCode = useCallback((code: string) => {
    onChangeCountryCode(code);
    onClose()
  }, [])
  
  return (
    <ul className={classNames(cls.PhoneCodesSelect, className)}>
      {data && data.map((code) => (
        <li
          className={cls.phoneCodeItem}
          key={code.country_code}
          onClick={() => handleChangeCountryCode(code.country_code)}
        >
          <img
            className={cls.countryIcon}
            src={BACKEND_BASE_URL + code.flag}
            alt=""
          />
          <span className={cls.countryCode}>
            {code.phone_code}
          </span>
          <span className={cls.countryName}>
            {code.country}
          </span>
        </li>
      ))}
    </ul>
  )
})