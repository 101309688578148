import { memo, useCallback, useEffect, useRef, useState } from "react";
import classNames from 'classnames'
import cls from './CodeConfirmation.module.scss';
import { CodeConfirmationHeader } from "../../CodeConfirmationHeader";
import { Button } from "@newComponents/shared/Button/Button";
import { useDispatch } from "react-redux";
import { authActions } from "@store/slices/auth";
import { CodeConfirmationInput } from "../../CodeConfirmationInput";

interface CodeConfirmationProps {
  onSendCode: () => void;
  className?: string;
}

export const CodeConfirmation = memo((props: CodeConfirmationProps) => {
  const { onSendCode, className } = props;
  const [timer, setTimer] = useState(60);
  const [isSendedCodeAgain, setIsSendedCodeAgain] = useState(false);
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const dispatch = useDispatch();

  const handleSendCodeAgain = useCallback(() => {
    console.log('sended again')
    if (onSendCode) {
      dispatch(onSendCode());
      setIsSendedCodeAgain(true);
    }
  }, [])

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(timerRef.current!);
          return 0; // Остановить таймер на 0
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current!); // Очистка интервала при размонтировании
    };
  }, []);

  const handleChangeStep = useCallback(() => {
    dispatch(authActions.setChangeStep('entries'))
  }, [])

  return (
    <div className={classNames(cls.CodeConfirmation, className)}>
      <CodeConfirmationHeader />
      <CodeConfirmationInput
        className={cls.input}
      />
      <div className={cls.footer}>
        {!isSendedCodeAgain && (timer > 0 ? (
          <p className={cls.description}>
            Send the code again in <span className={cls.additional}>{timer} seconds</span>
          </p>
        ) : (
          <p className={cls.description}>
            Send code <a className={cls.additional} onClick={handleSendCodeAgain}>again</a>
          </p>
        ))}
        {isSendedCodeAgain && (
          <p className={cls.description}>
            The code has been sent
          </p>
        )}
        <Button
          className={cls.button}
          theme="clear"
          onClick={handleChangeStep}
        >
          Change the number
        </Button>
      </div>
    </div>
  );
});