import {
  eachDayOfInterval, endOfMonth, format, getDaysInMonth,
} from 'date-fns';
import { createArrayFromNumber } from './createArrayFromNumber';

const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const monthNamesEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getFormatDateWithoutTime(dateString: string) {
  /**
   * Принимает строку даты и преобразует её в формат DDMMYYYY
   */
  const createdDate = new Date(dateString);
  const day = createdDate.getDate();
  const month = createdDate.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
  const year = createdDate.getFullYear();
  return `${day.toString().padStart(2, '0')}.${month
    .toString()
    .padStart(2, '0')}.${year}`;
}

export function formatDateToDDMMYYYYWithMonthAndTime(dateString: string, language?: string) {
  /**
   * Принимает строку даты и преобразует её в формат День Месяц Год Часы:Минуты
   */
  const createdDate = new Date(dateString);
  const day = createdDate.getDate();
  const monthName = language === 'en' ? monthNamesEN[createdDate.getMonth()] : monthNames[createdDate.getMonth()];
  const year = createdDate.getFullYear();
  const hours = createdDate.getHours();
  const minutes = createdDate.getMinutes();

  return `${day
    .toString()
    .padStart(2, '0')} ${monthName} ${year} ${hours}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

export function getMonthName(monthIndex: string | number) {
  const monthIndexNumber = Number(monthIndex);
  if (monthIndexNumber < 0 || monthIndexNumber > 11) {
    throw new Error('Индекс месяца должен быть от 0 до 11.');
  }

  const year = new Date().getFullYear();

  const date = new Date(year, monthIndexNumber, 1);

  return format(date, 'MMMM');
}

export const getWeekDaysOfMonth = (year: number, monthIndex: number) => {
  const now = new Date();

  const start = new Date(year, monthIndex, 1);
  const end = endOfMonth(start);

  const daysInMonth = eachDayOfInterval({ start, end });

  const weeks = [];
  let currentWeek = [];

  for (const day of daysInMonth) {
    const dayOfWeek = day.getDay();

    if (dayOfWeek === 1 && currentWeek.length > 0) {
      weeks.push(currentWeek);
      currentWeek = [];
    }

    currentWeek.push(day.getDate());
  }

  if (currentWeek.length > 0) {
    weeks.push(currentWeek);
  }

  const firstWeek = weeks[0];
  const lastWeek = weeks[weeks.length - 1];
  const weekDaysCount = 7;

  if (firstWeek.length < 7) {
    const prevMonth = new Date(year, monthIndex - 1);
    const daysCountInPrevMonth = getDaysInMonth(prevMonth);
    const differenceWeekOfDaysCount = weekDaysCount - firstWeek.length;
    const daysInPrevMonthArray = createArrayFromNumber(daysCountInPrevMonth)
      .slice(-differenceWeekOfDaysCount);
    weeks[0] = [...daysInPrevMonthArray, ...firstWeek];
  }

  if (lastWeek.length < 7) {
    const differenceWeekOfDaysCount = weekDaysCount - lastWeek.length;
    const differenceWeekOfDaysArray = createArrayFromNumber(differenceWeekOfDaysCount);
    weeks[weeks.length - 1] = [...lastWeek, ...differenceWeekOfDaysArray];
  }

  return weeks;
};

export interface TimeInfo {
  minutes: number;
  seconds: number;
}

export function getFormatTime({ minutes, seconds }: TimeInfo) {
  return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}
