import { memo } from "react";
import classNames from 'classnames'
import cls from './InstructionStepChangeTeam.module.scss';
import { ProfileTeams } from "@newComponents/ui/profile/ProfileTeams";
import { mockTeams } from "../model/teams";
import { Button } from "@newComponents/shared/Button/Button";
import { Link } from "react-router-dom";

interface InstructionStepChangeTeamProps {
  className?: string;
}

export const InstructionStepChangeTeam = memo((props: InstructionStepChangeTeamProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.InstructionStepChangeTeam, className)}>
      <ProfileTeams mockTeams={mockTeams} />
      <div className={cls.info}>
        <h3 className={cls.title}>
          Обучение
        </h3>
        <p className={cls.description}>
          Вы находитесь в разделе «Выбор команды». Для начала необходимо
          перейти в список турниров чтобы принять участие в турнире.
        </p>
        <div className={cls.buttons}>
          <Link to="/instruction/live-tournament">
            <Button className={cls.button} theme="contained-green">
              Продолжить
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
})