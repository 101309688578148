import { memo } from "react";
import classNames from 'classnames'
import cls from './SignUpModalDataEntry.module.scss';
import { SignUpModalDataEntryForm } from "../../SignUpModalDataEntryForm";
import { AuthChangeType } from "../../AuthChangeType";

interface SignUpModalDataEntryProps {
  className?: string;
}

export const SignUpModalDataEntry = memo((props: SignUpModalDataEntryProps) => {
  const { className } = props;
  return (
    <div className={classNames(className)}>
      <h3 className={cls.title}>
        Sign Up
      </h3>
      <AuthChangeType />
      <SignUpModalDataEntryForm />
    </div>
  )
})