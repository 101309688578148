import { useCallback, useState } from 'react';

export function useMenu() {
  const [open, setOpen] = useState<boolean | string>(false);

  const handleOpen = useCallback((value?: string) => {
    setOpen(value || true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    handleOpen,
    handleClose,
  };
}
