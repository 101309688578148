import {
  memo,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import SecondCloseIcon from '@assets/icons/second-close.svg';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import cls from './Menu.module.scss';

import { Button } from '../Button/Button';
import { Portal } from '../Portal/Portal';

interface MenuProps {
  title?: string;
  isOpen?: boolean;
  children: ReactNode;
  onClose?: () => void;
  className?: string;
}

const ANIMATION_VISIBLE_DELAY = 50;
const OPENING_TRANSITION_DELAY = 150;

export const Menu = memo((props: MenuProps) => {
  const {
    title, children, isOpen, onClose, className,
  } = props;
  const [isMounted, setIsMounted] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout>>;

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
      timerRef.current = setTimeout(() => {
        setIsAnimated(true);
      }, ANIMATION_VISIBLE_DELAY);
    }
  }, [isOpen, isMounted]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, [])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
      setIsAnimated(false);
      timerRef.current = setTimeout(() => {
        setIsMounted(false);
      }, OPENING_TRANSITION_DELAY);
    }
  }, [onClose]);

  if (!isMounted) {
    return null;
  }

  return (
    <Portal>
      <menu className={classNames(cls.Menu, className, { [cls.isOpen]: isAnimated && isOpen })}>
        <div className={cls.header}>
          <h3 className={cls.menuTitle}>
            {title || 'Title'}
          </h3>
          <Button
            theme="clear"
            className={cls.closeButton}
            onClick={handleClose}
          >
            <SecondCloseIcon />
          </Button>
        </div>
        {children}
      </menu>
    </Portal>
  );
});
