import { TournamentDetails } from "@services/tournaments.service";

export const mockTournament: TournamentDetails = {
  "id": 1049,
  "name": "fri23 13",
  "image": null,
  "date_start": "2025-02-07T23:00:00.280326+03:00",
  "duration_break": 1,
  "duration_round": 5,
  "place": "Австрия",
  "flag": "/django_static/phone_codes/at.svg",
  "cover": "Искусственная трава",
  "category": "NFT 1000",
  "participants": 16,
  "price": 2,
  "description": "",
  "level": 2,
  "status_tournament": "ИДЕТ",
  "winner": null,
  "round": [
      {
          "name": "Круг 1",
          "reward_money": 0,
          "reward_experience": 0,
          "reward_item": null,
          "achievements": [],
          "nft": null,
          "mystery_box": null,
          "date_end": null,
          "participants": 16
      },
      {
          "name": "Круг 2",
          "reward_money": 0,
          "reward_experience": 0,
          "reward_item": null,
          "achievements": [],
          "nft": null,
          "mystery_box": null,
          "date_end": null,
          "participants": 8
      },
      {
          "name": "Круг 3",
          "reward_money": 0,
          "reward_experience": 0,
          "reward_item": null,
          "achievements": [],
          "nft": null,
          "mystery_box": null,
          "date_end": null,
          "participants": 4
      },
      {
          "name": "Финал",
          "reward_money": 0,
          "reward_experience": 0,
          "reward_item": null,
          "achievements": [],
          "nft": null,
          "mystery_box": null,
          "date_end": null,
          "participants": 2
      }
  ],
  "loosers": [],
  "current_round_end": null,
  "is_break": false
}