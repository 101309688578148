import { confirmCode } from "@store/services/signUp";
import classNames from "classnames";
import { memo, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cls from './CodeConfirmationInput.module.scss';
import { authActions } from "@store/slices/auth";

interface CodeConfirmationInputProps {
  className?: string;
}

const CODE_LENGTH = 4

export const CodeConfirmationInput = memo((props: CodeConfirmationInputProps) => {
  const { className } = props;

  const inputRefs = useRef([]);
  const code = useSelector((state) => state.auth.code)
  const authError = useSelector(state => state.auth.authError)
  const dispatch = useDispatch();

  const codeArr = useMemo(() => {
    const arr = new Array(CODE_LENGTH).fill().map((i, index) => code[index] || '')
    return arr
  }, [code]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newCode = [...codeArr];
      newCode[index] = value.slice(-1); // сохраняем только последнюю введенную цифру
      dispatch(authActions.setCode(newCode.join('')));

      // Переключаемся на следующий инпут
      if (value && index < CODE_LENGTH - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (code.length === 4) {
      console.log('effect')
      dispatch(confirmCode())
    }
  }, [code])

  return (
    <div className={classNames(cls.CodeConfirmation, className)}>
      <div className={cls.inputBlock}>
        {codeArr.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputRefs.current[index] = el)}
            maxLength={1}
            className={classNames(cls.input, digit && cls.active, authError && cls.inputError)}
          />
        ))}
      </div>
        {authError && (
          <p className={cls.error}>
            {authError}
          </p>
        )}
    </div>
  )
})