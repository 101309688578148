import {
  FC, memo, MouseEvent, MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import CloseIcon from '@assets/icons/close.svg';
import cls from './Modal.module.scss';
import { Portal } from '../Portal/Portal';

type ModalThemes = 'grey' | 'grey_opacity'

interface ModalProps {
  className?: string,
  children?: ReactNode,
  contentClassName?: string;
  theme?: ModalThemes;
  isOpen?: boolean,
  onClose?: () => void,
  lazy?: boolean,
}

const ANIMATION_DELAY = 300;

export const Modal: FC<ModalProps> = (props) => {
  const {
    className,
    children,
    contentClassName,
    isOpen,
    theme = 'grey',
    onClose,
    lazy,
  } = props;

  const [isClosing, setIsClosing] = useState(false);
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout>>;
  const [isMounted, setIsMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const visibleTimerRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout>>

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (lazy) {
      visibleTimerRef.current = setTimeout(() => {
        setIsVisible(true)
      })
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  const handleClose = useCallback(() => {
    if (onClose) {
      setIsClosing(true);
      timerRef.current = setTimeout(() => {
        onClose();
        setIsClosing(false);
      }, ANIMATION_DELAY);
    }
  }, [onClose]);

  const handleContentClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  // const handleKeyDown = useCallback((e: any) => {
  //   if (e.key === 'Escape') {
  //     handleClose();
  //   }
  // }, [handleClose]);

  // useEffect(() => {
  //   if (isOpen) {
  //     window.addEventListener('keydown', handleKeyDown);
  //   }

  //   return () => {
  //     clearTimeout(timerRef.current);
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [isOpen, handleKeyDown]);
  
  const modalClassNames = useMemo(() => classNames(
    cls.Modal,
    className,
    cls[theme],
    {
      [cls.opened]: !lazy && isOpen ? true : isVisible,
      [cls.isClosing]: isClosing,
    }
  ), [className, theme, isOpen, isVisible, lazy, isClosing]);

  if (lazy && !isMounted) {
    return null;
  }

  return (
    <Portal>
      <div className={modalClassNames}>

        <div className={cls.overlay} onClick={handleClose}>
          <div className={classNames(cls.content, contentClassName)} onClick={handleContentClick}>
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
