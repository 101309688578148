import { memo } from "react";
import classNames from 'classnames'
import cls from './InstructionStepStart.module.scss';
import { TournamentsInfo } from "@newComponents/screens/TournamentsInfo";
import { Button } from "@newComponents/shared/Button/Button";
import { TournamentsInfoHeader } from "@newComponents/ui/tournaments/TournamentsInfoHeader";
import { TournamentsInfoMode } from "@newComponents/ui/tournaments/TournamentsInfoMode";
import { TournamentsNews } from "@newComponents/ui/tournaments/news/TournamentsNews";
import { Link } from "react-router-dom";

interface InstructionStepStartProps {
  className?: string;
}

export const InstructionStepStart = memo((props: InstructionStepStartProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.InstructionStepStart, className)}>
      <TournamentsInfoHeader className={cls.header} />
      <TournamentsInfoMode className={cls.mode} isInstruction />
      <TournamentsNews />
      <div className={cls.info}>
        <h3 className={cls.title}>
          Обучение
        </h3>
        <p className={cls.description}>
          Вы находитесь в разделе «Информация о турнирах». Для начала необходимо
          перейти в список турниров чтобы принять участие в турнире.
        </p>
        <div className={cls.buttons}>
          {/* <Button className={cls.button} theme="contained_black">
            Закрыть
          </Button> */}
          <Link to="/instruction/tournaments-list">
            <Button className={cls.button} theme="contained-green">
              Продолжить
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
})