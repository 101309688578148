import { FormEvent, memo, useCallback } from "react";
import classNames from 'classnames'
import cls from './LoginDataEntries.module.scss';
import { Button } from "@newComponents/shared/Button/Button";
import { Input } from "@newComponents/shared/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { authActions, AuthFields, AuthFormType } from "@store/slices/auth";
import { login } from "@store/services/login";
import { checkEmail, checkNumber, checkPassword } from "@utils/validate";
import { AuthChangeType } from "../../AuthChangeType";
import { PhoneInput } from "../../PhoneInput";
import { Link } from "react-router-dom";

interface LoginDataEntriesProps {
  className?: string;
}

function validate(username: string, password: string, type: AuthFormType) {
  const errors: AuthFields = {};
  const passwordError = checkPassword(password);

  if (type === 'email') {
    const emailError = checkEmail(username);
    if (emailError) {
      errors.username = emailError;
    }
  } else {
    const phoneNumberError = checkNumber(username);
    if (phoneNumberError) {
      errors.username = phoneNumberError;
    }
  }

  if (passwordError) {
    errors.password = passwordError;
  }

  return errors;
}

export const LoginDataEntries = memo((props: LoginDataEntriesProps) => {
  const { className } = props;
  const username = useSelector((state) => state.auth.username);
  const password = useSelector((state) => state.auth.password);
  const usernameError = useSelector((state) => state.auth.validateErrors.username);
  const passwordError = useSelector((state) => state.auth.validateErrors.password);
  const authError = useSelector((state) => state.auth.authError);
  const authLoading = useSelector((state) => state.auth.isLoading);
  const dispatch = useDispatch();
  const formType = useSelector((state) => state.auth.form_type)

  const handleChangeUsername = useCallback((value: string) => {
    dispatch(authActions.setUsername(value));
  }, [dispatch]);
  
  const handleChangePassword = useCallback((value: string) => {
    dispatch(authActions.setPassword(value));
  }, [dispatch]);

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    const errors = validate(username, password, formType);
    dispatch(authActions.setValidateErrors(errors));
    if (Object.keys(errors).length > 0) return;
    dispatch(login());
  }, [username, password, dispatch]);

  const handleReset = useCallback(() => {
    dispatch(authActions.setChangeStep('reset'))
  }, [])

  const handleChangeCountryCode = useCallback((countryCode: string) => {
    dispatch(authActions.setCountryCode(countryCode));
  }, [dispatch])

  return (
    <div className={classNames(cls.LoginDataEntries, className)}>
      <h3 className={cls.title}>
        Sign In
      </h3>
      <AuthChangeType />
      <form className={cls.form} onSubmit={handleSubmit}>
        {formType === 'phone' ? (
          <PhoneInput
            className={cls.inputBlock}
            placeholder="9892329898"
            onChange={handleChangeUsername}
            onChangeCountryCode={handleChangeCountryCode}
            error={authError || usernameError}
          />
        ) : (
          <Input
            className={cls.inputBlock}
            placeholder="example@mail.com"
            theme="dark_grey"
            onChange={handleChangeUsername}
            error={authError || usernameError}
          />
        )}
        <Input
          className={classNames(cls.inputBlock, cls.passwordInputBlock)}
          inputClassName={cls.input}
          type="password"
          autoComplete="off"
          placeholder="password"
          value={password}
          theme='dark_grey'
          onChange={handleChangePassword}
        />
        <Button
          className={cls.forgotButton}
          type="button"
          theme="clear"
          onClick={handleReset}
        >
          Forgot password?
        </Button>
        <Button
          type="submit"
          className={cls.submitButton}
          theme="contained-green"
          disabled={authLoading}
        >
          Sign in
        </Button>
      </form>
    </div>
  )
})