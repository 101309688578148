import classNames from 'classnames';
import { FormEvent, memo, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WechatIcon from '@assets/icons/wechat.svg';
import GoogleIcon from '@assets/icons/google.svg';
import FacebookIcon from '@assets/icons/facebook.svg';
import MetamaskIcon from '@assets/icons/metamask.svg';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '@store/services/login';
import { authActions, type AuthFields } from '@store/slices/auth';
import { checkEmail, checkPassword } from '@utils/validate';
import cls from './LoginModal.module.scss';
import { Modal } from '@newComponents/shared/Modal/Modal';
import { Input } from 'postcss';
import { Button } from '@newComponents/shared/Button/Button';
import { LoginDataEntries } from '../../LoginDataEntries';
import { ForgotPassword } from '../../ForgotPassword';
import { ForgotPasswordConfirmation } from '../../ForgotPasswordConfirmation';
import { CodeConfirmation } from '../../CodeConfirmation';
import { recovery, updatePassword } from '@store/services/recovery';
import { AuthCreatePassword } from '../../AuthCreatePassword';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const LoginModal = memo((props: LoginModalProps) => {
  const { isOpen, onClose, className } = props;
  const dispatch = useDispatch();
  const step = useSelector((state) => state.auth.step)

  useEffect(() => {
    return () => {
      dispatch(authActions.clearAuthData())
    }
  }, [])

  return (
    <Modal
      theme="grey_opacity"
      className={classNames(cls.LoginModal, className)}
      isOpen={isOpen}
      lazy
      onClose={onClose}
    >
      {step === 'entries' && (
        <>
          <LoginDataEntries />
          <div className={cls.lineBlock}>
            <div className={cls.line} />
            <span>or</span>
            <div className={cls.line} />
          </div>
          <ul className={cls.oauthList}>
            <li className={classNames(cls.oauthItem)}>
              <Link className={classNames(cls.oauthLink, cls.wechatLink)} to="#">
                <WechatIcon />
              </Link>
            </li>
            <li className={classNames(cls.oauthItem)}>
              <Link className={classNames(cls.oauthLink, cls.googleLink)} to="#">
                <GoogleIcon />
              </Link>
            </li>
            <li className={classNames(cls.oauthItem)}>
              <Link className={classNames(cls.oauthLink, cls.facebookLink)} to="#">
                <FacebookIcon />
              </Link>
            </li>
          </ul>
          <Link className={cls.metamaskLink} to="#">
            <MetamaskIcon />
            <span>Войти с помощью MetaMask</span>
          </Link>
        </>
      )}
      {step === 'reset' && (
        <ForgotPassword />
      )}
      {step === 'code' && (
        <CodeConfirmation onSendCode={recovery} />
      )}
      {step === 'password' && (
        <AuthCreatePassword onCreatePassword={updatePassword} />
      )}
    </Modal>
  );
});