import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@newComponents/shared/Button/Button';
import { Link } from 'react-router-dom';
import cls from './TournamentsInfoMode.module.scss';
import { TournamentsFriendsListModal } from '../../TournamentsFriendsListModal';

interface TournamentsInfoModeProps {
  isInstruction?: boolean;
  className?: string;
}

export const TournamentsInfoMode = memo((props: TournamentsInfoModeProps) => {
  const { isInstruction, className } = props;
  const [isOpenFriendsModal, setIsOpenFriendsModal] = useState(false);

  const handleOpenFriendsModal = useCallback(() => {
    setIsOpenFriendsModal(true);
  }, [])

  const handleCloseFriendsModal = useCallback(() => {
    setIsOpenFriendsModal(false);
  }, [])

  return (
    <div className={classNames(cls.TournamentsInfoMode, className)}>
      <div className={classNames(cls.modeBlock, cls.firstModeBlock)}>
        <div className={cls.modeInfo}>
          <p className={cls.modeInfoDate}>
            The nearest: Oct. 9-14
          </p>
          <div className={cls.modeInfoTextBlock}>
            <h3 className={cls.modeTitle}>
              PRO League
            </h3>
            <p className={cls.modeDescription}>
              Main tournament of team
            </p>
          </div>
        </div>
        <Link
          className={cls.link}
          to={isInstruction ? "/instruction/tournaments-list" : "/tournaments/pro"}
        >
          <Button className={cls.button} theme="contained-grey">
            Join the game
          </Button>
        </Link>
      </div>

      <div className={cls.modeBlock}>
        <div className={classNames(cls.modeInfo, cls.modeInfoFree)}>
          <div className={cls.modeInfoTextBlock}>
            <h3 className={cls.modeTitle}>
              Free match
            </h3>
            <p className={cls.modeDescription}>
            Get rewards and bonuses for participating in the free match!
            </p>
          </div>
        </div>
        <Link className={cls.link} to="/tournaments/pro/?price=free">
          <Button
            className={cls.button}
            theme="contained-grey"
          >
            Join free game
          </Button>
        </Link>
      </div>

      <div className={cls.modeBlock}>
        <div className={classNames(cls.modeInfo, cls.modeInfoFriends)}>
          <div className={cls.modeInfoTextBlock}>
            <h3 className={cls.modeTitle}>
              Friendly Match
            </h3>
            <p className={cls.modeDescription}>
              Main tournament of team
            </p>
          </div>
        </div>
        <Button
          className={cls.button}
          theme="contained-grey"
          onClick={handleOpenFriendsModal}
        >
          Create match
        </Button>
      </div>

      {isOpenFriendsModal && (
        <TournamentsFriendsListModal
          isOpen={isOpenFriendsModal}
          onClose={handleCloseFriendsModal}
        />
      )}
    </div>
  );
});
