import { memo, useEffect } from "react";
import classNames from 'classnames';
import cls from './SignUpModal.module.scss';
import { Modal } from "@newComponents/shared/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { SignUpModalCodeConfirmation } from "../../SignUpModalCodeConfirmation";
import { SignUpModalDataEntry } from "../../SignUpModalDataEntry";
import { SignUpCreatePassword } from "../../SignUpCreatePassword";
import { authActions } from "@store/slices/auth";
import { CodeConfirmation } from "../../CodeConfirmation";
import { registration, signUp } from "@store/services/signUp";
import { AuthCreatePassword } from "../../AuthCreatePassword";

interface SignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const SignUpModal = memo((props: SignUpModalProps) => {
  const { isOpen, lazy, onClose, className } = props;
  const step = useSelector((state) => state.auth.step)
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(authActions.clearAuthData())
    }
  }, [])

  return (
    <Modal
      lazy
      theme="grey_opacity"
      className={classNames(cls.SignUpModal, className)}
      isOpen={isOpen}
      onClose={onClose}
    >
      {step === 'entries' && (
        <SignUpModalDataEntry />
      )}
      {step === 'code' && (
        <CodeConfirmation onSendCode={signUp} />
      )}
      {step === 'password' && (
        <AuthCreatePassword onCreatePassword={registration} />
      )}
    </Modal>
  )
})