import { authActions } from "@store/slices/auth";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export function useChangeAuthType() {
  const dispatch = useDispatch();
  
  const handleChangePhoneType = useCallback(() => {
    dispatch(authActions.setFormType('phone'));
  }, [dispatch])
  
  const handleChangeEmailType = useCallback(() => {
    dispatch(authActions.setFormType('email'));
  }, [dispatch])

  return {
    handleChangePhoneType,
    handleChangeEmailType
  }
}