import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { type ArticleType, useFetchTournamentsNewsQuery } from '@services/news.service';
import cls from './TournamentsNews.module.scss';
import { TournamentsNewsList } from '../../TournamentsNewsList';
import { TournamentsArticle } from '../../TournamentsArticle';

interface TournamentsNewsProps {
  className?: string;
}

export const TournamentsNews = memo((props: TournamentsNewsProps) => {
  const { className } = props;
  const { data } = useFetchTournamentsNewsQuery();
  const [currentArticle, setCurrentArticle] = useState<ArticleType>();

  const handleChangeArticle = useCallback((article: ArticleType) => {
    setCurrentArticle(article);
  }, []);

  return (
    <div className={classNames(cls.TournamentsNews, className)}>
      <h3 className={cls.title}>
        Tournaments news
      </h3>
      {data && (
        <div className={cls.newsBlock}>
          <TournamentsNewsList
            news={data}
            onChangeArticle={handleChangeArticle}
          />
          <TournamentsArticle
            article={currentArticle || data[0]}
          />
        </div>
      )}
    </div>
  );
});
