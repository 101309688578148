import { memo, useCallback, useState } from 'react';
import SecondLogoIcon from '@assets/icons/second-logo.svg';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import cls from './HeaderNotAuthContent.module.scss';
import { Button } from '../../../../shared/Button/Button';
import { useMenu } from '@hooks/useMenu';
import { SignUpModal } from '@newComponents/ui/auth/SignUpModal';
import { LoginModal } from '@newComponents/ui/auth/LoginModal';

interface HeaderNotAuthContentProps {
  className?: string;
}

export const HeaderNotAuthContent = memo((props: HeaderNotAuthContentProps) => {
  const { className } = props;
  const { open, handleOpen, handleClose } = useMenu();

  const handleOpenLoginModal = useCallback(() => {
    handleOpen('login');
  }, [handleOpen]);

  const handleOpenSignUpModal = useCallback(() => {
    handleOpen('register');
  }, [handleOpen]);

  return (
    <div className={classNames(cls.HeaderNotAuthContent, className)}>
      <Link to="/" className={cls.logoLink}>
        <div className={cls.logoBlock}>
          <SecondLogoIcon />
        </div>
      </Link>
      <div className={cls.buttons}>
        <Button
          className={classNames(cls.button, cls.firstButton)}
          onClick={handleOpenSignUpModal}
        >
          Register
        </Button>
        <Button
          className={cls.button}
          theme="outlined-white"
          onClick={handleOpenLoginModal}
        >
          Sign in
        </Button>
      </div>
      {open === 'login' && (
        <LoginModal
          isOpen
          onClose={handleClose}
        />
      )}
      {open === 'register' && (
        <SignUpModal
          isOpen
          onClose={handleClose}
        />
      )}
    </div>
  );
});
