import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';
import { TeamType } from './tournaments.service';

export type Rarity = 'ADVANCED' | 'RARE' | 'UNIQUE' | 'LEGENDARY' | 'COMMON'

export interface PlayerCard {
  id: number;
  price?: number;
  circulation_number?: number;
  image_card: string;
}

interface FetchPlayersCardsResponse {
  next: string;
  pages: number;
  previous: string | null;
  results: {
    id: number;
    price: number;
    nft: {
      circulation_number: number;
      image_card: string;
    }
  }[]
}

interface FetchPlayersCardsResponseNormalize {
  next: string;
  pages: number;
  previous: string | null;
  results: PlayerCard[]
}

export interface CardItem {
  id: number;
  image: null | string;
  name?: string;
  price?: number;
}

interface FetchPlayersItemsResponse {
  pages: 1,
  next: null,
  previous: null,
  results: CardItem[]
}

export interface CrewCardType {
  description: string;
  full_name: string;
  id: number;
  image: null | string;
  occupation: string
}

type FetchMyClubPlayersItemsResultsType = {
  item?: CardItem;
  crew_card?: CrewCardType
}

interface FetchMyClubPlayersItemsResponse {
  pages: 1,
  next: null,
  previous: null,
  results: FetchMyClubPlayersItemsResultsType[]
}

interface AwardTranslation {
  category: string;
  description: string;
  name: string;
}

export interface Translations {
  en: AwardTranslation;
  ru: AwardTranslation
}

interface Award {
  category: string;
  description: string;
  id: number;
  image: string;
  name: string;
  serial_number: null | number;
  translations: Translations
}

type FetchAwardsResponse = Award[];

interface PlayerShotInfoItem {
  name: string;
  id: number
}

export interface PlayerCardShortInfo {
  image_card: string;
  name: string;
  circulation_number: number;
  id: number;
  player_items: {
    first_item?: PlayerShotInfoItem;
    second_item?: PlayerShotInfoItem;
    crew?: PlayerShotInfoItem;
  }
}

interface FetchMyClubCardsForUpdatingPlayer {
  id: number;
  item: CardItem
}

interface FetchMyClubCardsForUpdatingPlayerResponse {
  next: string;
  pages: number;
  previous: string | null;
  results: FetchMyClubCardsForUpdatingPlayer[]
}

interface AddItemToPlayerProps {
  playerId: string | number;
  playerItemIds: {
    first_item: number;
    second_item: number;
  };
}

interface AddCrewToPlayerProps {
  playerId: number | string;
  crewId: number
}

interface FetchMyClubCrewForUpdatings {
  next: string;
  pages: number;
  previous: string | null;
  results: {
    id: number;
    on_sale: boolean;
    crew_card: CrewCardType
  }[]
}

export interface PlayerReplacement {
  id: number;
  circulation_number: number;
  image_card: string
}

interface FetchPlayersForReplacementsResponse {
  pages: number;
  next: string;
  previous: null | string;
  results: PlayerReplacement[]
}

interface FetchPlayersProps {
  userId: number;
  playerType: string
}

interface ReplacePlayer {
  teamId: number;
  oldCardId: number;
  newCardId: number;
}

interface FetchPlayerCard {
  id: number;
  price: number;
  nft: {
    rarity: string;
    name: string;
  },
  product_id: number;
}

export const cardsAPI = createApi({
  reducerPath: 'cardsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchPlayerCard: builder.query<PlayerCardShortInfo | FetchPlayerCard, {playerId: string, isForMyClub?: boolean}>({
      query: ({playerId, isForMyClub = true}) => ({
        url: `/nft/nft_card/${playerId}/`,
        params: {
          type: isForMyClub && 'my_club'
        }
      }),
      providesTags: ['playerCard']
    }),
    buyPlayerCard: builder.mutation<void, number | string>({
      query: (productId) => ({
        url: `/market/buy_product/${productId}/`,
        method: 'POST'
      }),
      invalidatesTags: ['fetchMarketCards']
    }),
    fetchPlayersForReplacements: builder.query<FetchPlayersForReplacementsResponse, FetchPlayersProps>({
      query: ({userId, playerType}) => ({
        url: `/nft/nft_card/?on_sale=false&on_team=false&player_type=${playerType}&user=${userId}`,
      }),
      providesTags: ['fetchPlayersForReplacements']
    }),
    fetchTeams: builder.query<TeamType[], string | number>({
      query: (userId) => ({
        url: `/nft/teams/${userId}/`,
      }),
      providesTags: ['teams'],
    }),
    replacePlayer: builder.mutation<void, ReplacePlayer>({
      query: ({newCardId, oldCardId, teamId}) => ({
        url: `/nft/player_team/${teamId}/${oldCardId}/${newCardId}/`,
        method: 'PATCH'
      }),
      invalidatesTags: ['fetchPlayersForReplacements', 'teams']
    }),
    fetchMarketCards: builder.query<FetchPlayersCardsResponseNormalize, {[key: string]: string}>({
      transformResponse: (response: FetchPlayersCardsResponse) => ({
        ...response,
        results: response.results.map((item) => ({ ...item, ...item.nft, ...item.nft.nft })),
      }),
      query: (filtersQuery) => ({
        url: '/market/nft_product_auction/',
        params: filtersQuery,
      }),
      providesTags: ['fetchMarketCards']
    }),
    fetchMarketCardsItems: builder.query<FetchPlayersItemsResponse, void>({
      query: () => ({
        url: '/market/products/items_sell/',
      }),
    }),
    fetchMyClubCardsForUpdatingPlayer: builder.query<FetchMyClubCardsForUpdatingPlayerResponse, void>({
      query: () => ({
        url: '/items/items/?type=boost',
      }),
      providesTags: ['updatingPlayers']
    }),
    addItemToPlayer: builder.mutation<void, AddItemToPlayerProps>({
      query: (info) => ({
        url: `/items/use_item/boost_nftcard/${info.playerId}/`,
        method: 'PATCH',
        body: info.playerItemIds
      }),
      invalidatesTags: ['updatingPlayers', 'playerCard']
    }),
    fetchMyClubCrewForUpdatingPlayer: builder.query<FetchMyClubCrewForUpdatings, void>({
      query: () => ({
        url: '/crew_card/crew_cards/?type=card',
      }),
      providesTags: ['crew']
    }),
    addCrewToPlayer: builder.mutation<void, AddCrewToPlayerProps>({
      query: ({playerId, crewId}) => ({
        method: 'POST',
        url: `/crew_card/use_crew/${playerId}/${crewId}/`
      }),
      invalidatesTags: ['crew', 'playerCard']
    }),
    fetchMyClubCardsItems: builder.query<FetchMyClubPlayersItemsResponse, void>({
      query: () => ({
        url: '/account/user_collection/?page=1',
      }),
    }),
    fetchMyClubCards: builder.query<FetchPlayersCardsResponseNormalize, void>({
      query: () => ({
        url: '/account/user_nftcards/',
        method: 'GET',
      }),
    }),
    fetchAwards: builder.query<FetchAwardsResponse, void>({
      query: () => ({
        url: '/items/achievements/',
      }),
    }),
  }),
});

export const {
  useFetchPlayerCardQuery,
  useAddItemToPlayerMutation,
  useFetchMarketCardsQuery,
  useReplacePlayerMutation,
  useFetchMyClubCrewForUpdatingPlayerQuery,
  useFetchPlayersForReplacementsQuery,
  useFetchMarketCardsItemsQuery,
  useFetchMyClubCardsItemsQuery,
  useFetchMyClubCardsForUpdatingPlayerQuery,
  useFetchMyClubCardsQuery,
  useBuyPlayerCardMutation,
  useAddCrewToPlayerMutation,
  useFetchTeamsQuery,
  useFetchAwardsQuery,
} = cardsAPI;
