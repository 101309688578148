import { memo } from 'react';
import classNames from 'classnames';
import { Tournament } from '@services/tournaments.service';
import cls from './TournamentsList.module.scss';
import { TournamentCard } from '../../TournamentCard';

interface TournamentsListProps {
  isInstruction?: boolean;
  tournaments: Tournament[];
  className?: string;
}

export const TournamentsList = memo((props: TournamentsListProps) => {
  const { isInstruction, tournaments, className } = props;

  return (
    <ul className={classNames(cls.TournamentsList, className)}>
      {tournaments.map((item, index) => (
        <li
          key={item.id}
          className={classNames(cls.item, isInstruction && index === 0 && cls.instructionItem)}
        >
          <TournamentCard isInstruction={isInstruction} tournament={item} />
        </li>
      ))}
    </ul>
  );
});
