import { memo } from "react";
import classNames from 'classnames';
import cls from './Instruction.module.scss';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { InstructionStepStart } from "@newComponents/ui/instruction/InstructionStepStart";
import { Page } from "@newComponents/shared/Page/Page";
import { InstructionStepTournamentsList } from "@newComponents/ui/instruction/InstructionStepTournamentsList";
import { InstructionStepChangeTeam } from "@newComponents/ui/instruction/InstructionStepChangeTeam";
import { InstructionStepLiveTournament } from "@newComponents/ui/instruction/InstructionStepLiveTournament";

interface InstructionProps {
  className?: string;
}

export const Instruction = memo((props: InstructionProps) => {
  const { className } = props;
  const { pathname } = useLocation();
  return (
    <Page
      containerClassName={classNames(
        cls.instructionContainer,
        pathname.includes('live-tournament') && cls.liveTournament
      )}
      className={classNames(cls.Instruction, className)}
    >
      <div className={cls.opacityBlock}/>
      <Routes>
        <Route path="start" element={<InstructionStepStart />} />
        <Route path="tournaments-list" element={<InstructionStepTournamentsList />} />
        <Route path="change-team" element={<InstructionStepChangeTeam />} />
        <Route path="live-tournament" element={<InstructionStepLiveTournament />} />
        <Route path="*" element={<Navigate to="/instruction/start" />} />
      </Routes>
    </Page>
  )
})