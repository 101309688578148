import SearchIcon from '@assets/icons/search.svg';
import BalanceIcon from '@assets/icons/balance.svg';
import NotificationsIcon from '@assets/icons/notifications.svg';

export type HeaderMenuType = 'balance' | 'notifications' | 'search';

interface HeaderAuthItem {
  to?: string;
  Icon: string;
  menuType?: HeaderMenuType;
}

export const headerAuthItems: HeaderAuthItem[] = [
  {
    Icon: SearchIcon,
    menuType: 'search'
  },
  {
    Icon: BalanceIcon,
    menuType: 'balance',
  },
  {
    Icon: NotificationsIcon,
    menuType: 'notifications',
  },
];
