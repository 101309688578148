import { memo, MutableRefObject, useEffect, useRef } from 'react';
import classNames from 'classnames';
import casePrizeVideo from '@assets/videos/case-prize.mp4';
import cls from './MysteryCasePrize.module.scss';
import { Button } from '@newComponents/shared/Button/Button';

interface MysteryCasePrizeProps {
  onShowResult: () => void;
  className?: string;
}

export const MysteryCasePrize = memo((props: MysteryCasePrizeProps) => {
  const { onShowResult, className } = props;
  const blockRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    blockRef.current.addEventListener('click', onShowResult);

    return () => {
      if (blockRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        blockRef.current.removeEventListener('click', onShowResult);
      }
    }
  }, [])

  return (
    <div
      className={classNames(cls.MysteryCasePrize, className)}
      ref={blockRef}
    >
      <video
        className={cls.video}
        autoPlay
        onEnded={onShowResult}
      >
        <source src={casePrizeVideo} type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>
      <Button
        className={cls.button}
        theme='contained_black'
        onClick={onShowResult}
      >
        Пропустить видео
      </Button>
    </div>
  );
});
