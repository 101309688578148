import { ButtonHTMLAttributes, memo } from 'react';
import classNames from 'classnames';
import cls from './Button.module.scss';

type ButtonThemes = 'contained-purple' |
                  'outlined-white' |
                  'contained-green' |
                  'contained-white' |
                  'contained-grey' |
                  'contained_red' |
                  'opacity' |
                  'clear' | 
                  'contained_opacity-black' |
                  'contained_black'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isHovered?: boolean;
  theme?: ButtonThemes;
}

export const Button = memo((props: ButtonProps) => {
  const {
    children,
    theme = 'contained-purple',
    className,
    isHovered = true,
    ...rest
  } = props;
  return (
    <button
      className={classNames(cls.Button, cls[theme], className, isHovered && cls.isHovered)}
      {...rest}
    >
      {children}
    </button>
  );
});
