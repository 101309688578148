import { memo } from "react";
import classNames from 'classnames'
import cls from './CodeConfirmationHeader.module.scss';
import { useSelector } from "react-redux";

interface CodeConfirmationHeaderProps {
  className?: string;
}

export const CodeConfirmationHeader = memo((props: CodeConfirmationHeaderProps) => {
  const { className } = props;
  const formType = useSelector((state) => state.auth.form_type)
  const username = useSelector(state => state.auth.username)

  return (
    <div className={classNames(cls.CodeConfirmationHeader, className)}>
      <h3 className={cls.title}>
        Enter the code
      </h3>
      <p className={cls.description}>
        The confirmation code has been sent to the {formType} <span className={cls.username}>{username}</span>
      </p>
    </div>
  )
})