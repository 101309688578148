import { memo, useEffect } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useMenu } from '@hooks/useMenu';
import { NotificationsMenu } from '@newComponents/ui/NotificationsMenu';
import { PageTitle } from '@newComponents/ui/PageTitle';
import { headerAuthItems, type HeaderMenuType } from '../../model/header';
import cls from './HeaderAuthContent.module.scss';
import { HeaderProfileCard } from '../HeaderProfileCard/HeaderProfileCard';
import { Menu } from '../../../../shared/Menu/Menu';
import { Balance } from '../../../../ui/Balance';
import { Modal } from '@newComponents/shared/Modal/Modal';
import { SearchFriendsModal } from '@newComponents/ui/SearchFriendsModal';

interface HeaderAuthContentProps {
  className?: string;
}

export const HeaderAuthContent = memo((props: HeaderAuthContentProps) => {
  const { className } = props;
  const { open, handleOpen, handleClose } = useMenu<HeaderMenuType>();
  const { pathname } = useLocation();

  useEffect(() => {
    handleClose();
  }, [pathname])
  

  return (
    <div className={classNames(cls.HeaderAuthContent, className)}>
      <PageTitle />
      <div className={cls.control}>
        <ul className={cls.links}>
          {headerAuthItems.map((item, index) => (
            <li className={classNames(cls.link, index === 1 && cls.withMargin)}>
              <Link to={item.to} onClick={() => handleOpen(item.menuType)}>
                <item.Icon />
              </Link>
            </li>
          ))}
        </ul>
        <HeaderProfileCard />
        <Menu
          isOpen={open === 'balance'}
          onClose={handleClose}
          title="Баланс"
        >
          <Balance />
        </Menu>
        <Menu
          isOpen={open === 'notifications'}
          onClose={handleClose}
          title="Уведомления"
        >
          <NotificationsMenu onCloseMenu={handleClose} />
        </Menu>
        {open === 'search' && (
          <SearchFriendsModal
            onClose={handleClose}
            isOpen
          />
        )}
      </div>
    </div>
  );
});
