import { FormEvent, memo, useCallback } from "react";
import { AuthChangeType } from "../../AuthChangeType";
import classNames from 'classnames';
import cls from './ForgotPassword.module.scss';
import { PhoneInput } from "../../PhoneInput";
import { Button } from "@newComponents/shared/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "@store/slices/auth";
import { Input } from "@newComponents/shared/Input/Input";
import { recovery } from "@store/services/recovery";

interface ForgotPasswordProps {
  className?: string;
}

export const ForgotPassword = memo((props: ForgotPasswordProps) => {
  const { className } = props;
  const dispatch = useDispatch();
  const formType = useSelector((state) => state.auth.form_type)
  const authError = useSelector((state) => state.auth.authError);
  const authLoading = useSelector((state) => state.auth.isLoading);
  const usernameError = useSelector((state) => state.auth.validateErrors.username);

  const handleChangeUsername = useCallback((value: string) => {
    dispatch(authActions.setUsername(value));
  }, [dispatch])

  const handleChangeCountryCode = useCallback((countryCode: string) => {
    dispatch(authActions.setCountryCode(countryCode));
  }, [dispatch])

  const handleSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    dispatch(recovery());
  }, []) 

  return (
    <div className={classNames(cls.ForgotPassword, className)}>
      <h3 className={cls.title}>
        Recovery Access
      </h3>
      <AuthChangeType />
      <form className={cls.form} onSubmit={handleSubmit}>
        {formType === 'phone' ? (
          <PhoneInput
            className={cls.inputBlock}
            placeholder="9892329898"
            onChange={handleChangeUsername}
            onChangeCountryCode={handleChangeCountryCode}
            error={authError || usernameError}
          />
          ) : (
            <Input
              className={cls.inputBlock}
              placeholder="example@mail.com"
              theme="dark_grey"
              onChange={handleChangeUsername}
              error={authError || usernameError}
            />
          )}
        <Button
          className={cls.button}
          theme="contained-green"
          disabled={authLoading}
        >
          Go next
        </Button>
      </form>
    </div>
  )
})