import { createAsyncThunk } from "@reduxjs/toolkit";
import { $api } from "@utils/api";
import { AxiosError } from "axios";
import { login } from "./login";
import { LoginResponse } from "./login";

export interface RegistrationResponse {
  info: LoginResponse
}

export const signUp = createAsyncThunk<void, void>('auth/signUp', async (_, thunkAPI) => {
  console.log('sign up')
  const { username, form_type, country_code } = thunkAPI.getState().auth;
  try {
    console.log('sign up')
    await $api.post('/account/send_code/register/', form_type === 'phone' ? {
      form_type,
      phone: username,
      country_code
    } : {
      email: username,
      form_type
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('An error occurred during registration');
  }
})

export const confirmCode = createAsyncThunk<void, void>('auth/confirmCode', async (_, thunkAPI) => {
  const { username, password, country_code, form_type, code } = thunkAPI.getState().auth;
  try {
    console.log('sign up')
    await $api.post('/account/confirm_code/', form_type === 'phone' ? {
      recipient: username,
      code,
      country_code
    } : {
      recipient: username,
      code,
      country_code: null
    });
  } catch (error) {
    return thunkAPI.rejectWithValue('An error occurred during confirm code');
  }
})

export const registration = createAsyncThunk<RegistrationResponse, void>('auth/registration', async (_, thunkAPI) => {
  const { username, password, country_code, form_type, code } = thunkAPI.getState().auth;
  try {
    const {data} = await $api.post<RegistrationResponse>(
      '/account/registration/',
      form_type === 'phone' ? {
      recipient: username,
      password,
      country_code
    } : {
      recipient: username,
      password,
      country_code: null
    });
    localStorage.setItem('user', JSON.stringify(data.info));
    return data
  } catch (error) {
    if (error.request?.response?.text.includes('Code not verified')) {
      return thunkAPI.rejectWithValue('Incorrect verification code');
    }
    return thunkAPI.rejectWithValue('An error occurred during registration');
  }
})