import { configureStore } from '@reduxjs/toolkit';
import { mysteryAPI } from '@services/mystery.service';
import { ratingApi } from '@services/rating.service';
import { cardsAPI } from '@services/cards.service';
import { profileAPI } from '@services/profile.service';
import { tournamentsAPI } from '@services/tournaments.service';
import { newsAPI } from '@services/news.service';
import userActivePopupReducer from './slices/IsActivePopupSlice';
import friendlyMatchResult from './slices/FriendlyMatchResult';
import timerReducer from './slices/Timer';
import friendIdReducer from './slices/friendlyMatchSlice';
import UpgradeItemOrStaffReducer from './slices/UpgradeSlice';
import isNewTeam from './slices/isNewTeam';
import TennisGameContinue from './slices/TennisGameContinue';
import { authReducer } from './slices/auth';
import { marketReducer } from './slices/market';
import { phoneCodesAPI } from '@services/phoneCodes.service';
import { usersAPI } from '@services/users.service';

export const store = configureStore({
  reducer: {
    learningContext: userActivePopupReducer,
    timer: timerReducer,
    friendlyMatchResult,
    friendContext: friendIdReducer,
    upgradeItemOrStaff: UpgradeItemOrStaffReducer,
    isNewTeam,
    tennisGameContinue: TennisGameContinue,
    auth: authReducer,
    market: marketReducer,
    [mysteryAPI.reducerPath]: mysteryAPI.reducer,
    [ratingApi.reducerPath]: ratingApi.reducer,
    [cardsAPI.reducerPath]: cardsAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [tournamentsAPI.reducerPath]: tournamentsAPI.reducer,
    [newsAPI.reducerPath]: newsAPI.reducer,
    [phoneCodesAPI.reducerPath]: phoneCodesAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    mysteryAPI.middleware,
    ratingApi.middleware,
    cardsAPI.middleware,
    profileAPI.middleware,
    tournamentsAPI.middleware,
    newsAPI.middleware,
    phoneCodesAPI.middleware,
    usersAPI.middleware
  ),
});
