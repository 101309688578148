import { memo } from 'react';
import classNames from 'classnames';
import { useFetchTransactionsQuery } from '@services/profile.service';
import getFormatDateTime from '@utils/formatDateTime';
import cls from './ProfileTransactions.module.scss';
import { EmptyListError } from '@newComponents/ui/EmptyListError';

interface ProfileTransactionsProps {
  className?: string;
}

const mockItems = [
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
  {
    title: 'Покупка коробка с ценой 0.10',
    date: '27 сентября 2024 16:50',
    count: '0.10',
  },
];

export const ProfileTransactions = memo((props: ProfileTransactionsProps) => {
  const { className } = props;
  const { data } = useFetchTransactionsQuery();
  console.log(data);
  if (data) {
    if (data.results.length === 0) {
      return <EmptyListError text="The list of transactions is empty" />
    }
    return (
      <ul className={classNames(cls.ProfileTransactions, className)}>
        {data.results.filter((item) => item.operation === 'ПОКУПКА').map((transaction) => (
          <li key={transaction.id} className={cls.item}>
            <div>
              <h3 className={cls.title}>
                {transaction.name}
              </h3>
              <span className={cls.date}>
                {getFormatDateTime(new Date(transaction.created_time))}
              </span>
            </div>
            <span className={cls.count}>
              -
              {transaction.amount}
              $
            </span>
          </li>
        ))}
      </ul>
    );
  }
});
