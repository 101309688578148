import { memo, useCallback, useState } from 'react';
import {
  MysteryCase,
  MysteryCaseDrop,
  MysteryCaseInfo as MysteryCaseInfoType,
  useFetchMysteryCaseQuery,
  useOpenMysteryCaseMutation,
} from '@services/mystery.service';
import { Button } from '@newComponents/shared/Button/Button';
import { useParams } from 'react-router-dom';
import { BACKEND_BASE_URL } from '@constants/constants';
import classNames from 'classnames';
import { PageLoader } from '@newComponents/ui/PageLoader';
import { MysteryCaseRoulette } from '../../MysteryCaseRoulette';
import cls from './MysteryCaseInfo.module.scss';
import { MysteryCasePrize } from '../../MysteryCasePrize';
import { MysteryCaseResult } from '../../MysteryCaseResult';

interface MysteryCaseInfoProps {
  className?: string;
}

export enum Status {
  DEFAULT = 'default',
  OPENING = 'opening',
  OPENED = 'opened',
  RESULT = 'result'
}

export const MysteryCaseInfo = memo((props: MysteryCaseInfoProps) => {
  const { className } = props;
  const { mysteryId } = useParams<{mysteryId: string}>();
  const {
    data: mysteryCaseData,
    isLoading: mysteryCaseLoading
  } = useFetchMysteryCaseQuery(mysteryId);
  const [openMysteryCase, {
    isLoading: openMysteryCaseLoading,
    data: openMysteryCaseResult,
  }] = useOpenMysteryCaseMutation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const handleOpenMysteryCase = useCallback(async () => {
    try {
      await openMysteryCase(mysteryId).unwrap();
      setStatus(Status.OPENING);
    } catch (error) {
      console.log(error);
    }
  }, [openMysteryCase, mysteryId]);

  const handleDrop = useCallback(() => {
    setStatus(Status.OPENED);
  }, []);

  const handleShowResult = useCallback(() => {
    setStatus(Status.RESULT);
  }, []);

  const handleResetStatus = useCallback(() => {
    setStatus(Status.DEFAULT);
  }, []);

  if (mysteryCaseLoading) {
    return <PageLoader />;
  }

  if (mysteryCaseData) {
    return (
      <div className={classNames(cls.MysteryCaseInfo, className)}>
        {status === Status.DEFAULT && (
          <>
            <img
              className={cls.image}
              src={BACKEND_BASE_URL + mysteryCaseData.image}
              alt={mysteryCaseData.rarity_type}
            />
            <div className={cls.controlContent}>
              <h3 className={cls.name}>
                {mysteryCaseData.rarity_type}
              </h3>
              <span className={cls.price}>
                {mysteryCaseData.price}
                $
              </span>
              <Button
                className={cls.button}
                theme="contained-green"
                disabled={openMysteryCaseLoading}
                onClick={handleOpenMysteryCase}
              >
                Открыть коробку
              </Button>
            </div>
          </>
        )}

        {status === Status.OPENING && (
          <MysteryCaseRoulette
            isOpened={status === Status.OPENED}
            drops={mysteryCaseData.drops as MysteryCaseDrop[]}
            onDrop={handleDrop}
          />
        )}
        {status === Status.OPENED && (
          <MysteryCasePrize onShowResult={handleShowResult} />
        )}
        {status === Status.RESULT && !openMysteryCaseLoading && (
          <MysteryCaseResult
            image={openMysteryCaseResult?.image_card || openMysteryCaseResult?.image}
            onResetStatus={handleResetStatus}
            onOpenMysteryCase={handleOpenMysteryCase}
            name={openMysteryCaseResult?.name || ''}
          />
        )}
      </div>
    );
  }
});
