import { FormEvent, memo, useCallback } from "react";
import classNames from 'classnames'
import cls from './AuthCreatePassword.module.scss';
import { Input } from "@newComponents/shared/Input/Input";
import { Button } from "@newComponents/shared/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { authActions, AuthFields } from "@store/slices/auth";
import { registration } from "@store/services/signUp";
import { checkPassword } from "@utils/validate";

interface AuthCreatePasswordProps {
  onCreatePassword: () => void;
  className?: string;
}


function validate(password: string, againPassword: string) {
  const errors: AuthFields = {};
  const passwordError = checkPassword(password);
  const againPasswordError = againPassword !== password && 'Passwords are not equal'
  if (passwordError) {
    errors.password = passwordError;
  }
  if (againPasswordError) {
    errors.againPassword = againPasswordError
  }
  return errors;
}

export const AuthCreatePassword = memo((props: AuthCreatePasswordProps) => {
  const { onCreatePassword, className } = props;
  const password = useSelector((state) => state.auth.password)
  const againPassword = useSelector((state) => state.auth.againPassword)
  const dispatch = useDispatch();
  const passwordError = useSelector((state) => state.auth.validateErrors.password);
  const againPasswordError = useSelector((state) => state.auth.validateErrors.againPassword);
  const authError = useSelector((state) => state.auth.authError);
  const authLoading = useSelector(state => state.auth.isLoading) 

  const handleSubmit = useCallback((e: FormEvent) => {  
    e.preventDefault();
    const errors = validate(password, againPassword);
    if (Object.keys(errors).length > 0) {
      dispatch(authActions.setValidateErrors(errors));
      return
    };
    if (onCreatePassword) {
      dispatch(onCreatePassword());
    }
  }, [password, againPassword, onCreatePassword])

  const handleChangePassword = useCallback((value: string) => {
    dispatch(authActions.setPassword(value));
  }, [dispatch])

  const handleChangeAgainPassword = useCallback((value: string) => {
    dispatch(authActions.setAgainPassword(value));
  }, [dispatch])

  return (
    <div className={classNames(cls.AuthCreatePassword, className)}>
      <h3 className={cls.title}>
        Create password
      </h3>
      <form onSubmit={handleSubmit}>
        <Input
          className={cls.inputBlock}
          inputClassName={cls.input}
          placeholder="password"
          type="password"
          value={password}
          autoComplete="off"
          defaultValue=""
          error={passwordError}
          onChange={handleChangePassword}
        />
        <Input
          className={cls.inputBlock}
          inputClassName={cls.input}
          type="password"
          placeholder="type password again"
          defaultValue=""
          autoComplete="off"
          value={againPassword}
          error={againPasswordError || authError}
          onChange={handleChangeAgainPassword}
        />
        <Button
          type="submit"
          className={cls.submitButton}
          theme="contained-green"
          disabled={authLoading}
        >
          Sign in
        </Button>
      </form>
    </div>
  )
})