import { createAsyncThunk } from "@reduxjs/toolkit";
import { $api } from "@utils/api";
import { NavigateFunction } from "react-router-dom";

export const getRewardOfLearning = createAsyncThunk(
  'auth/getRewardOfInstruction',
  async (_, thunkAPI) => {
  try {
    const { data } = await $api.get('/tournaments/learning/reward_for_learning/', {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('user')) ?
        `Bearer ${JSON.parse(localStorage.getItem('user')).token}` :
        null
      }
    });

    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Error with getting reward');
  }
})