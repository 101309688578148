import { Modal } from '@newComponents/shared/Modal/Modal';
import { memo } from 'react';
import classNames from 'classnames';
import { Button } from '@newComponents/shared/Button/Button';
import cls from './TournamentResultModal.module.scss';
import { Link } from 'react-router-dom';

export type TournamentResultType = 'looser' | 'winner';

interface TournamentResultModalProps {
  type?: TournamentResultType;
  isInstruction?: boolean;
  onClose: () => void;
  className?: string;
}

export const TournamentResultModal = memo((props: TournamentResultModalProps) => {
  const { type, isInstruction, onClose, className } = props;

  return (
    <Modal
      contentClassName={classNames(cls.TournamentResultModal, className)}
      isOpen={Boolean(type)}
      lazy
      onClose={onClose}
    >
      <h3 className={cls.title}>
        {type === 'looser' ? "You've lost" : "You've won"}
      </h3>
      <p className={cls.description}>
        {!isInstruction ? 'Champions League: NFT Tennis Masters' : 'Вы успешно прошли обучение!'}
      </p>
      {!isInstruction ? (
        <Button
          className={cls.button}
          theme={type === 'looser' ? 'contained_red' : 'contained-green'}
          onClick={onClose}
        >
          Go to the tournament grid
        </Button>
      ) : (
        <Link to="/" className={cls.buttonLink}>
          <Button
            className={cls.button}
            theme="contained-green"
            onClick={onClose}
          >
            Завершить
          </Button>
        </Link>
      )}
    </Modal>
  );
});
