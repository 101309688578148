import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';

export interface ArticleType {
  created_at: string;
  id: number;
  image: string;
  text: string;
  title: string;
}

export const newsAPI = createApi({
  reducerPath: 'newsAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchNews: builder.query<ArticleType[], void>({
      query: () => ({
        url: '/news/news/',
      }),
    }),
    fetchTournamentsNews: builder.query<ArticleType[], void>({
      query: () => ({
        url: '/tournaments/news/',
      }),
    }),
  }),
});

export const {
  useFetchNewsQuery,
  useFetchTournamentsNewsQuery,
} = newsAPI;
