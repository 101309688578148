import { Page } from '@newComponents/shared/Page/Page';
import { memo, useEffect } from 'react';
import classNames from 'classnames';
import { TournamentsInfoHeader } from '@newComponents/ui/tournaments/TournamentsInfoHeader';
import { TournamentsInfoMode } from '@newComponents/ui/tournaments/TournamentsInfoMode';
import { TournamentsNews } from '@newComponents/ui/tournaments/news/TournamentsNews';
import cls from './TournamentsInfo.module.scss';
import {
  useFetchCurrentFriendlyTournamentQuery
} from '@services/tournaments.service';
import { Link } from 'react-router-dom';
import { Container } from '@newComponents/layout/Container';

interface TournamentsInfoProps {
  isInstruction?: boolean;
  className?: string;
}

export const TournamentsInfo = memo((props: TournamentsInfoProps) => {
  const { isInstruction, className } = props;  
  const {data} = useFetchCurrentFriendlyTournamentQuery(null, {
    pollingInterval: 10000,
  });

  console.log(data)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Page
      className={cls.tournamentInfoPage}
      containerClassName={classNames(cls.TournamentsInfo, className)}
    >
      {data && (
        <Link
          className={cls.tournamentLive}
          to="/tournaments/friendly-match"
        >
          <Container fullWidth>
            <p className={cls.tournamentsLiveTitle}>Friendly Match Live!</p>
          </Container>
        </Link>
      )}
      <TournamentsInfoHeader className={cls.header} />
      <TournamentsInfoMode className={cls.mode} isInstruction={isInstruction} />
      <TournamentsNews />
    </Page>
  );
});
