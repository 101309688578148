import { createAsyncThunk } from "@reduxjs/toolkit";
import { $api } from "@utils/api";

export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async (_, thunkAPI) => {
  try {
    const {data} = await $api.get('/account/fullinfo/');
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Error with fetching user');
  }
})