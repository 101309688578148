import { BACKEND_URL } from '@constants/constants';
import { UserModel } from '@models/User';
import axios from 'axios';

const user = typeof localStorage !== 'undefined' ?
  JSON.parse(localStorage.getItem('user')) as UserModel :
  null;

export const $api = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    Authorization: user && `Bearer ${user.token}`,
  }
});
