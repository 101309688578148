import { BACKEND_URL } from "@constants/constants";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from "./AuthService";

interface PhoneCode {
  country: string;
  country_code: string;
  flag: string;
  mask: string;
  phone_code: string
}

export const phoneCodesAPI = createApi({
  reducerPath: 'phoneCodesAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchPhoneCodes: builder.query<PhoneCode[], void>({
      query: () => '/phone-codes/'
    }) 
  })
})

export const {
  useFetchPhoneCodesQuery
} = phoneCodesAPI;