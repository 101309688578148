import {
  ChangeEvent, InputHTMLAttributes, memo, useCallback,
} from 'react';
import classNames from 'classnames';
import cls from './Input.module.scss';

export type HTMLInput = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;
export type InputThemes = 'grey' | 'dark_grey'

interface InputProps extends HTMLInput {
  label?: string;
  labelClassName?: string;
  inputClassName?: string;
  theme?: InputThemes;
  error?: string;
  onChange?: (val: string) => void;
}

export const Input = memo((props: InputProps) => {
  const {
    value,
    inputClassName,
    labelClassName,
    label,
    name,
    type,
    theme = 'grey',
    className,
    onChange,
    error,
    ...rest
  } = props;

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  }, [onChange]);

  return (
    <div className={classNames(cls.Input, className)}>
      {label && (
        <label
          className={classNames(cls.label, labelClassName)}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <input
        className={classNames(cls.field, inputClassName, error && cls.errorField, cls[theme])}
        type={type}
        value={value}
        name={name}
        id={name}
        onChange={handleChange}
        {...rest}
      />
      {error && (
        <p className={cls.error}>
          {error}
        </p>
      )}
    </div>
  );
});
