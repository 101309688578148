import { memo, useMemo } from 'react';
import classNames from 'classnames';
import { TournamentCalendarItem } from '@services/tournaments.service';
import { format } from 'date-fns';

import hamburgMockImage from '@assets/images/hamburg-mock.png';
import cls from './CalendarTournamentBadge.module.scss';
import { Link } from 'react-router-dom';

interface CalendarTournamentBadgeProps {
  tournament: TournamentCalendarItem;
  className?: string;
}

export const CalendarTournamentBadge = memo((props: CalendarTournamentBadgeProps) => {
  const { tournament, className } = props;

  const tournamentDate = new Date(tournament.date_start);
  const formatTournamentTime = format(tournamentDate, 'HH:mm:ss');

  return (
    <Link
      className={classNames(cls.CalendarTournamentBadge, className)}
      to={`/tournaments/${tournament.id}`}
    >
        <img
          className={cls.image}
          src={tournament.image || hamburgMockImage}
          alt={tournament.category}
        />
        <div className={cls.infoBlock}>
          <span className={cls.name}>
            {tournament.category}
          </span>
          <span className={cls.country}>
            {tournament.place}, {formatTournamentTime}
          </span>
        </div>
    </Link>
  );
});
