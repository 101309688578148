import { Input } from "@newComponents/shared/Input/Input";
import { Modal } from "@newComponents/shared/Modal/Modal";
import { memo, useCallback, useEffect, useState } from "react";
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import cls from './SearchFriendsModal.module.scss';
import { useLazyFetchFriendsByNameQuery } from "@services/users.service";
import { SearchUserCard } from "@newComponents/ui/SearchUserCard";
import { Link, useLocation } from "react-router-dom";

interface SearchFriendsModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const SearchFriendsModal = memo((props: SearchFriendsModalProps) => {
  const { isOpen, onClose, className } = props;
  const [fetchFriends, {data}] = useLazyFetchFriendsByNameQuery();
  const [isEmptyField, setIsEmptyField] = useState(false);

  const debouncedFetchFriends = debounce((newValue: string) => {
    if (newValue !== '') {
      fetchFriends(newValue);
      setIsEmptyField(false);
    } else {
      setIsEmptyField(true);
    }
  }, 500);

  const handleChange = useCallback((newValue: string) => {
    debouncedFetchFriends(newValue);
  }, [debouncedFetchFriends]);

  return (
    <Modal
      contentClassName={classNames(cls.SearchFriendsModal, className)}
      lazy
      isOpen={isOpen}
      theme="grey_opacity"
      onClose={onClose}
    >
      <h3 className={cls.title}>
        Search Friends
      </h3>
      <p className={cls.description}>
        Search your friends by username
      </p>
      <Input
        className={cls.inputBlock}
        inputClassName={cls.input}
        placeholder="Enter @nickname..."
        onChange={handleChange}
      />
      {data && !isEmptyField && (data.length > 0 ? (
        <ul className={cls.list}>
          {data.map(user => (
            <li className={cls.item} key={user.id}>
              <Link className={cls.link} to={`/profile/${user.id}/info`}>
                <SearchUserCard
                  nickname={user.nickname}
                  avatar={user.avatar}
                />
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <p className={classNames(cls.description, cls.emptyText)}>
          User list is empty
        </p>
      ))}
    </Modal>
  );
});
