import { memo, useCallback, useState } from "react";
import classNames from 'classnames'
import cls from './ChangeTeamPlayerModal.module.scss';
import { Modal } from "@newComponents/shared/Modal/Modal";
import { BACKEND_BASE_URL } from "@constants/constants";
import {
  PlayerReplacement,
  useFetchPlayersForReplacementsQuery,
  useReplacePlayerMutation
} from "@services/cards.service";
import { Button } from "@newComponents/shared/Button/Button";
import { TeamPlayerType } from "@services/tournaments.service";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ChangeTeamPlayerModalProps {
  player: TeamPlayerType;
  teamId: number;
  onClose: () => void;
  className?: string;
}

export const ChangeTeamPlayerModal = memo((props: ChangeTeamPlayerModalProps) => {
  const { onClose, teamId, player, className } = props;
  const currentUserId = useSelector((state) => state.auth.user?.id)
  const { data } = useFetchPlayersForReplacementsQuery({
    playerType: player.player_type,
    userId: currentUserId,
  });
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerReplacement>();
  const [replacePlayer] = useReplacePlayerMutation();

  const handleReplacePlayer = useCallback(() => {
    toast.promise(
      replacePlayer({
        teamId,
        newCardId: selectedPlayer.id,
        oldCardId: player.id,
      }).unwrap(),
      {
        pending: 'The player is updating',
        success: 'The player was upgraded!',
        error: 'Error by replacing card.'
      }
    )
    setSelectedPlayer(null);
    onClose()
  }, [player, replacePlayer, selectedPlayer, teamId, currentUserId, selectedPlayer])

  return (
    <Modal
      isOpen
      theme="grey_opacity"
      contentClassName={classNames(cls.ChangeTeamPlayerModal, className)}
      onClose={onClose}
    >
      <div className={cls.changingCardBlock}>
        <div className={cls.changingCardInfo}>
          <h3 className={cls.title}>Replace the teammate</h3>
          <img
            className={classNames(cls.changingCard, player.trauma && cls.trauma)}
            src={BACKEND_BASE_URL + (selectedPlayer ? selectedPlayer.image_card : player.image_card)}
            alt=""
          />
        </div>
        <Button
          theme="contained-grey"
          className={cls.button}
          onClick={handleReplacePlayer}
          disabled={!selectedPlayer}
        >
          Select player
        </Button>
      </div>
      <ul className={cls.list}>
        {data && data.results.map(player => (
          <li className={cls.item} key={player.id}>
            <img
              className={cls.playerImage}
              src={BACKEND_BASE_URL + player.image_card}
              alt="player"
              onClick={() => setSelectedPlayer(player)}
            />
          </li>
        ))}
      </ul>
    </Modal>
  )
})