import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import cls from './PhoneInput.module.scss'; // Импортируйте ваши стили
import { Input } from '@newComponents/shared/Input/Input';
import { Button } from '@newComponents/shared/Button/Button';
import UpArrowIcon from '@assets/icons/up-arrow.svg';
import { PhoneCodesSelect } from '../../PhoneCodesSelect';
import { useSelector } from 'react-redux';

type HTMLInput = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>;
type InputThemes = 'grey' | 'dark_grey';

interface CountryCode {
  value: string; // Код страны
  label: string; // Название страны с кодом
}

const countryCodes: CountryCode[] = [
  { value: '+1', label: 'USA (+1)' },
  { value: '+44', label: 'UK (+44)' },
  { value: '+7', label: 'Russia (+7)' },
  // Добавьте другие коды стран по мере необходимости
];

interface PhoneInputProps extends HTMLInput {
  label?: string;
  labelClassName?: string;
  inputClassName?: string;
  error?: string;
  onChange?: (val: string) => void;
  onChangeCountryCode: (code: string) => void;
}

export const PhoneInput = memo((props: PhoneInputProps) => {
  const {
    value,
    inputClassName,
    labelClassName,
    label,
    name,
    className,
    error,
    onChange,
    onChangeCountryCode,
    ...rest
  } = props;
  const [collapsed, setCollapsed] = useState<boolean>();
  const currentCountryCode = useSelector((state) => state.auth.country_code)

  const handleCollapse = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setCollapsed((prevState) => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    setCollapsed(false)
  }, [])

  return (
    <div className={classNames(cls.PhoneInput, className)}>
      <Button
        type="button"
        theme='clear'
        className={cls.changeCountryButton}
        onClick={handleCollapse}
      >
        <span className={cls.phoneCode}>{currentCountryCode}</span>
        <UpArrowIcon className={classNames(cls.arrow, collapsed && cls.active)} />
      </Button>
      <Input
        className={cls.inputBlock}
        inputClassName={cls.input}
        type="text"
        onChange={onChange}
        {...rest}
      />
      {error && (
        <p className={cls.error}>
          {error}
        </p>
      )}
      {collapsed && (
        <PhoneCodesSelect
          onChangeCountryCode={onChangeCountryCode}
          onClose={handleClose}
        />
      )}
    </div>
  );
});