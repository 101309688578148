import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import cls from './NotificationsMenu.module.scss';
import {
  useFetchNotificationsQuery,
} from '@services/profile.service';
import { Button } from '@newComponents/shared/Button/Button';
import { Modal } from '@newComponents/shared/Modal/Modal';
import { ProfileTeams } from '@newComponents/ui/profile/ProfileTeams';
import { Team } from '@newComponents/ui/Team';
import {
  useAcceptFriendlyMatchMutation,
  useFetchTournamentTeamsQuery
} from '@services/tournaments.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface NotificationsMenuProps {
  onCloseMenu?: () => void;
  className?: string;
}

export const NotificationsMenu = memo((props: NotificationsMenuProps) => {
  const { onCloseMenu, className } = props;
  const { data } = useFetchNotificationsQuery();
  const { data: teams } = useFetchTournamentTeamsQuery();
  const [selectedTeamId, setSelectedTeamId] = useState<number>();
  const [participateTournamentId, setParticipateTournamentId] = useState<number>(null);
  const [acceptFriendlyTournament, {isLoading}] = useAcceptFriendlyMatchMutation();
  const navigate = useNavigate();

  const handleSelectTeam = useCallback((teamId: number) => {
    setSelectedTeamId(teamId);
  }, [])

  const handleToggleParticipateTournamentId = useCallback((tournamentId: number) => {
    setParticipateTournamentId(tournamentId)
  }, []);

  const handleClose = useCallback(() => {
    setParticipateTournamentId(null);
    setSelectedTeamId(null)
  }, [])

  const handleAcceptFriendlyTournament = useCallback(async () => {
    try {
      await acceptFriendlyTournament({
        teamId: selectedTeamId,
        tournamentId: participateTournamentId,
      }).unwrap()
      toast.success('You have successfully accepted an application to participate in the match!')
      navigate(`/tournaments/friendly-match`)
      handleClose()
      onCloseMenu()
    } catch (error) {
      toast.error('Error accepting match')
    }
  }, [acceptFriendlyTournament, participateTournamentId, selectedTeamId])

  return (
    <ul className={classNames(cls.NotificationsMenu, className)}>
      {data && data.results.map((notification) => (
        <li className={cls.notification} key={notification.id}>
          <h3 className={cls.body}>
            {notification.body}
          </h3>
          {notification.content_type === 'friendlymatch' && (
            <Button
              className={cls.button}
              theme='contained-green'
              onClick={() => handleToggleParticipateTournamentId(notification.object_id)}
            >
              Принять матч
            </Button>
          )}
        </li>
      ))}
      <Modal
        isOpen={Boolean(participateTournamentId)}
        theme='grey_opacity'
        onClose={handleClose}
      >
        <h3 className={cls.title}>
          Выбор команды
        </h3>
        {teams && teams.map((team) => (
          <Team
            select={{
              isSelected: selectedTeamId === team.id,
              onSelectTeam: handleSelectTeam
            }}
            key={team.id}
            team={team}
          />
        ))}
        <Button
          className={cls.participateButton}
          theme='contained-green'
          disabled={!participateTournamentId || !selectedTeamId || isLoading}
          onClick={handleAcceptFriendlyTournament}
        >
          Участвовать
        </Button>
      </Modal>
    </ul>
  );
});
