import { memo } from "react";
import classNames from 'classnames';
import cls from './MysteryCaseResult.module.scss';
import { Button } from "@newComponents/shared/Button/Button";
import { BACKEND_BASE_URL } from "@constants/constants";

interface MysteryCaseResultProps {
  onResetStatus: () => void;
  image?: string,
  onOpenMysteryCase: () => void;
  name: string;
  className?: string;
}

export const MysteryCaseResult = memo((props: MysteryCaseResultProps) => {
  const { onResetStatus, name, image, onOpenMysteryCase, className } = props;

  return (
    <div className={classNames(cls.MysteryCaseResult, className)}>
      {image ? (
        <img
          className={cls.image}
          src={BACKEND_BASE_URL + image}
          alt=""
        />
      ) : (
        <div className={cls.image}>
          <h3>
            Картинки нет :(
          </h3>
        </div>
      )}
      <h3 className={cls.name}>
        {name}
      </h3>
      <div className={cls.buttons}>
        <Button
          className={cls.button}
          theme="contained-green"
          onClick={onOpenMysteryCase}
        >
          Open box again
        </Button>
        <Button
          className={cls.button}
          theme="contained-grey"
          onClick={onResetStatus}
        >
          Close
        </Button>
      </div>
    </div>
  )
})