import { memo } from "react";
import classNames from 'classnames'
import cls from './EmptyListError.module.scss';

interface EmptyListErrorProps {
  text?: string
  className?: string;
}

export const EmptyListError = memo((props: EmptyListErrorProps) => {
  const { text = "List is empty", className } = props;

  return (
    <p className={classNames(cls.EmptyListError, className)}>
      {text}
    </p>
  )
})