import { memo } from "react";
import classNames from 'classnames'
import cls from './SearchUserCard.module.scss';
import { Avatar } from "@newComponents/shared/Avatar/Avatar";

interface SearchUserCardProps {
  avatar?: string;
  nickname: string;
  isSelected?: boolean;
  onClick?: () => void;
  className?: string;
}

export const SearchUserCard = memo((props: SearchUserCardProps) => {
  const { avatar, nickname, isSelected, onClick, className } = props;

  return (
    <div
      className={classNames(
        cls.SearchUserCard,
        className,
        isSelected && cls.active,
      )}
      onClick={onClick}
    >
      <Avatar
        className={cls.avatar}
        src={avatar}
      />
      <h3 className={cls.name}>
        {nickname}
      </h3>
    </div>
  )
})