export const mockTournamentsList = [
  {
    "id": 1050,
    "name": "sat02 13",
    "place": "Чили",
    "image": "/media/tournament/%D0%B8%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_2025-02-04_204858978.png",
    "participants": 4,
    "category": "NFT 500",
    "cover": "Искусственная трава",
    "status_tournament": "НЕ НАЧАЛСЯ",
    "is_participate": false,
    "price": 2
  },
  {
      "id": 1049,
      "name": "fri23 13",
      "place": "Австрия",
      "image": null,
      "participants": 16,
      "category": "NFT 1000",
      "cover": "Искусственная трава",
      "status_tournament": "НЕ НАЧАЛСЯ",
      "is_participate": false,
      "price": 2
  },
  {
      "id": 1048,
      "name": "fri21 13",
      "place": "Соединенные Штаты Америки",
      "image": null,
      "participants": 8,
      "category": "NFT 250",
      "cover": "Акрил",
      "status_tournament": "НЕ НАЧАЛСЯ",
      "is_participate": false,
      "price": 1
  },
]