import { memo } from "react";
import classNames from 'classnames'
import cls from './InstructionStepTournamentsList.module.scss';
import { TournamentCard } from "@newComponents/ui/tournaments/TournamentCard";
import { mockTournamentsList } from "../model/tournaments";
import { Tournaments } from "@newComponents/screens/Tournaments";
import { TournamentAds } from "@newComponents/ui/tournaments/TournamentAds";
import { TournamentsList } from "@newComponents/ui/tournaments/TournamentsList";
import { Button } from "@newComponents/shared/Button/Button";
import { Link } from "react-router-dom";

interface InstructionStepTournamentsListProps {
  className?: string;
}

export const InstructionStepTournamentsList = memo((props: InstructionStepTournamentsListProps) => {
  const { className } = props;

  return (
    <div
      className={classNames(cls.InstructionStepTournamentsList, className)}
    >
      <div className={cls.info}>
        <h3 className={cls.title}>
          Обучение
        </h3>
        <p className={cls.description}>
          Вы находитесь в разделе «Информация о турнирах». Для начала необходимо
          перейти в список турниров чтобы принять участие в турнире.
        </p>
        <div className={cls.buttons}>
          {/* <Button className={cls.button} theme="contained_black">
            Закрыть
          </Button> */}
          <Link to="/instruction/change-team">
            <Button className={cls.button} theme="contained-green">
              Продолжить
            </Button>
          </Link>
        </div>
      </div>
      <TournamentAds className={cls.ads} />
      <TournamentsList isInstruction tournaments={mockTournamentsList} />
    </div>
  )
})