import { BACKEND_URL } from '@constants/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthService from './AuthService';

interface Friend {
  avatar: null | string;
  first_name: string;
  id: number;
  is_follow: boolean;
  last_name: string;
  nickname: string;
}

export const usersAPI = createApi({
  reducerPath: 'usersAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: BACKEND_URL,
    prepareHeaders: (headers) => {
      const accessToken = AuthService.getCurrentUser()?.token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchUserFriends: builder.query<Friend[], number>({
      query: (userId) => ({
        url: `/account/${userId}/followers/`
      })
    }),
    fetchFriendsByName: builder.query<void, string>({
      query: (searchQuery) => ({
        url: 'account/search/',
        params: {
          query: searchQuery
        }
      })
    })
  })
});

export const {
  useFetchUserFriendsQuery,
  useLazyFetchFriendsByNameQuery
} = usersAPI;