import { memo } from 'react';
import classNames from 'classnames';
import type { ArticleType } from '@services/news.service';
import { BACKEND_BASE_URL } from '@constants/constants';
import cls from './TournamentsArticle.module.scss';

interface TournamentsArticleProps {
  article: ArticleType;
  className?: string;
}

export const TournamentsArticle = memo((props: TournamentsArticleProps) => {
  const { article, className } = props;

  return (
    <div className={classNames(cls.TournamentsArticle, className)}>
      <h3 className={cls.title}>
        {article.title}
      </h3>
      <img
        className={cls.image}
        src={BACKEND_BASE_URL + article.image}
        alt=""
      />
      <p className={cls.description}>
        {article.text}
      </p>
    </div>
  );
});
