import { UserModel } from '@models/User';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentUser } from '@store/services/getCurrentUser';
import { getRewardOfLearning } from '@store/services/getRewardOfLearning';
import { login, type LoginResponse } from '@store/services/login';
import { recovery, updatePassword } from '@store/services/recovery';
import { confirmCode, registration, RegistrationResponse, signUp } from '@store/services/signUp';

export type AuthFormType = 'email' | 'phone'

export interface AuthFields {
  username?: string;
  password?: string;
  code?: string;
  againPassword?: string;
  country_code?: string;
}

export type SignUpSteps = 'entries' | 'code' | 'password' | 'reset'

interface AuthSchema extends AuthFields {
  user?: UserModel;
  isLoading: boolean;
  validateErrors: AuthFields;
  authError: null | string,
  form_type: AuthFormType;
  fetchingUserLoading: boolean;
  step: SignUpSteps
}

const initialState: AuthSchema = {
  user: typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('user')),
  username: '',
  password: '',
  code: '',
  isLoading: false,
  validateErrors: {},
  authError: null,
  country_code: 'RU',
  form_type: 'email',
  step: 'entries',
  fetchingUserLoading: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.username = action.payload;
      state.validateErrors = {};
      state.authError = null;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
      state.validateErrors = {};
      state.authError = null;
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.country_code = action.payload;
    },
    setAgainPassword: (state, action: PayloadAction<string>) => {
      state.againPassword = action.payload;
      state.validateErrors = {};
      state.authError = null;
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setFormType: (state, action: PayloadAction<AuthFormType>) => {
      state.form_type = action.payload;
      state.authError = null;
      state.validateErrors = {};
      state.username = '';
      state.password = ''
      // state.step = 'entries'
    },
    logout: (state) => {
      state.user = null;
    },
    setValidateErrors: (state, action: PayloadAction<AuthFields>) => {
      state.validateErrors = action.payload;
    },
    setChangeStep: (state, action: PayloadAction<SignUpSteps>) => {
      state.code = '';
      state.authError = null;
      state.validateErrors = {};
      state.username = '';
      state.password = '';
      state.step = action.payload;
    },
    clearAuthData: (state) => {
      state.username = '';
      state.password = '';
      state.code = '';
      state.form_type = 'email';
      state.isLoading = false;
      state.step = 'entries'
      state.authError = null;
      state.validateErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending.type, (state) => {
      state.fetchingUserLoading = true;
    }),
    builder.addCase(getCurrentUser.fulfilled.type, (state, action: PayloadAction<UserModel>) => {
      state.user = action.payload;
      state.fetchingUserLoading = false;
    }),
    builder.addCase(getRewardOfLearning.fulfilled.type, (state) => {
      state.user.is_learned = true;
      const userFromStorage = JSON.parse(localStorage.getItem('user'));
      localStorage.setItem('user', JSON.stringify({
        ...userFromStorage,
        is_learned: true
      }));
    }),
    builder.addCase(login.pending.type, (state) => {
      state.isLoading = true;
    }),
    builder.addCase(login.fulfilled.type, (
      state,
      action: PayloadAction<LoginResponse>
    ) => {
      state.user = action.payload;
      state.isLoading = false;
    }),
    builder.addCase(login.rejected.type, (
      state,
      action: PayloadAction<string>
    ) => {
      state.authError = action.payload;
      state.isLoading = false;
    });
    builder.addCase(signUp.pending.type, (state) => {
      state.isLoading = true;
      state.authError = null;
    })
    builder.addCase(signUp.fulfilled.type, (state) => {
      state.step = 'code';
      state.isLoading = false;
    })
    builder.addCase(signUp.rejected.type, (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
      state.isLoading = false;
    })
    builder.addCase(confirmCode.pending.type, (state) => {
      state.isLoading = true;
      state.authError = null;
    })
    builder.addCase(confirmCode.fulfilled.type, (state, action: PayloadAction<string>) => {
      state.step = 'password';
      state.isLoading = false
    })
    builder.addCase(confirmCode.rejected.type, (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
      state.isLoading = false
    })
    builder.addCase(registration.rejected.type, (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
      state.isLoading = false
    })
    builder.addCase(registration.pending.type, (state, action: PayloadAction<string>) => {
      state.isLoading = true;
      state.authError = null;
    })
    builder.addCase(registration.fulfilled.type, (
      state,
      action: PayloadAction<RegistrationResponse>
    ) => {
      state.user = action.payload.info;
      state.isLoading = false
    }),
    builder.addCase(recovery.pending.type, (state) => {
      state.isLoading = true;
      state.authError = null;
    })
    builder.addCase(recovery.rejected.type, (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
      state.isLoading = false;
    })
    builder.addCase(recovery.fulfilled.type, (state) => {
      state.isLoading = false;
      state.step = 'code';
    })
    builder.addCase(updatePassword.pending.type, (state) => {
      state.isLoading = true;
      state.authError = null;
    })
    builder.addCase(updatePassword.fulfilled.type, (state, action: PayloadAction<UserModel>) => {
      state.isLoading = false;
      state.user = action.payload;
    })
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
