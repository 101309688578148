import { memo } from 'react';
import { CardPage } from '../../../../screens/CardPage/ui';
import frontImage from './front.jpg';
import backImage from './back.jpg'

export const ShowCard = memo(() => {
  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <CardPage
        frontImageLocal={frontImage}
        backImageLocal={backImage}
        rarity='LEGENDARY'
      />
    </div>
  )
})