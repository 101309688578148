import { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import AuthService from '@services/AuthService';
import { BACKEND_BASE_URL } from '@constants/constants';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '../../../../shared/Avatar/Avatar';
import cls from './HeaderProfileCard.module.scss';

import ProfileIcon from '@assets/icons/header/profile.svg';
import ReferalsIcon from '@assets/icons/header/referals.svg';
import TrainingIcon from '@assets/icons/header/training.svg';
import ExitIcon from '@assets/icons/header/exit.svg';
import { authActions } from '@store/slices/auth';

interface HeaderProfileCardProps {
  className?: string;
}

export const HeaderProfileCard = memo((props: HeaderProfileCardProps) => {
  const { className } = props;
  const user = useSelector((state) => state.auth?.user);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDropdown = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setDropdownVisible(!isDropdownVisible);
  }, [isDropdownVisible]);

  const handleLinkClick = useCallback((to: string) => {
    navigate(to);
    setDropdownVisible(false); // Закрыть выпадающий список при выборе
  }, [navigate]);

  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
    localStorage.removeItem('user')
    navigate('/')
    setDropdownVisible(false);
  }, [dispatch])

  useEffect(() => {
    const handleCloseDropdown = () => {
      setDropdownVisible(false);
    };

    document.body.addEventListener('click', handleCloseDropdown);

    return () => {
      document.body.removeEventListener('click', handleCloseDropdown);
    };
  }, []);

  return (
    <div className={classNames(cls.HeaderProfileCard, className)}>
      <div onClick={toggleDropdown} className={cls.profileLink}>
        <h3 className={cls.name}>
          {user.nickname}
        </h3>
        <Avatar src={user.avatar && BACKEND_BASE_URL + user.avatar} alt="" />
      </div>

        <nav className={classNames(cls.dropdown, isDropdownVisible && cls.active)}>
          <ul className={cls.list}>
            <li
              className={cls.item}
              role='link'
              onClick={() => handleLinkClick(`/profile/${user.id}`)}
            >
              <div className={cls.iconBlock}>
                <ProfileIcon className={cls.icon} />
              </div>
              <span className={cls.linkText}>My profile</span>
            </li>
            <li
              className={cls.item}
              role='link'
              onClick={() => handleLinkClick('/referal')}
            >
              <div className={cls.iconBlock}>
                <ReferalsIcon className={cls.icon} />
              </div>
              <span className={cls.linkText}>Referals</span>
            </li>
            <li
              className={cls.item}
              role='link'
              onClick={() => handleLinkClick('/mini-games')}
            >
              <div className={cls.iconBlock}>
                <TrainingIcon className={cls.icon} />
              </div>
              <span className={cls.linkText}>Trainings</span>
            </li>
            {/* <li className={cls.item}>
              <Link className={cls.link} to="/logout" onClick={handleLinkClick}>Выход</Link>
            </li> */}
          </ul>
          <div className={cls.item} onClick={handleLogout}>
            <div className={cls.iconBlock}>
              <ExitIcon className={cls.icon} />
            </div>
            <span className={cls.linkText}>Logout</span>
          </div>
        </nav>
    </div>
  );
});