import { memo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchProfileQuery } from '@services/profile.service';
import classNames from 'classnames';
import { Page } from '../../../shared/Page/Page';
import { ProfileHeader } from '../../../ui/profile/ProfileHeader';
import { ProfileInfoCard } from '../../../ui/profile/ProfileInfoCard';
import { Container } from '../../../layout/Container';
import { ProfileContent } from '../../../ui/profile/ProfileContent';
import cls from './Profile.module.scss';
import { Button } from '@newComponents/shared/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '@store/slices/auth';

interface ProfileProps {
  className?: string;
}

export const Profile = memo((props: ProfileProps) => {
  const { className } = props;
  const { profileId } = useParams<{ profileId: string }>();
  const currentUserId = useSelector((state) => state.auth.user?.id);
  const { data } = useFetchProfileQuery(profileId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
    localStorage.removeItem('user')
    navigate('/')
  }, [dispatch, navigate])

  return (
    <Page
      className={classNames(cls.Profile, className)}
      containerClassName={cls.profileContainer}
      fullWidth
    >
      <ProfileHeader />
      <Container className={cls.contentContainer}>
        <div className={cls.profileCardBlock}>
          <ProfileInfoCard
            className={cls.profileInfo}
            user={data}
          />
          {currentUserId === Number(profileId) && (
            <Button
              className={cls.button}
              theme='contained-grey'
              onClick={handleLogout}
            >
              Выход
            </Button>
        )}
        </div>
        <ProfileContent
          user={data}
        />
      </Container>
    </Page>
  );
});
