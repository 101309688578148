import { UserModel } from '@models/User';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from '@utils/api';

export interface LoginResponse extends UserModel {
  token: string;
  refresh_token: string;
}

export const login = createAsyncThunk<LoginResponse, void>('auth/login', async (_, thunkAPI) => {
  const { username, password, country_code } = thunkAPI.getState().auth;
  console.log(username, password);
  try {
    const { data } = await $api.post<LoginResponse>('/account/login/', {
      username, password, country_code
    });
    localStorage.setItem('user', JSON.stringify(data));
    return data;
  } catch (error) {
    const errorData = error.response.data;
    if (errorData.non_field_errors?.length) {
      return thunkAPI.rejectWithValue('Incorrect email or password');
    }
    return thunkAPI.rejectWithValue('There was an error logging in');
  }
});
