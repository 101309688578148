import { BACKEND_URL } from '@constants/constants';
import AuthService from '@services/AuthService';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PlayerCard } from '../../ui/PlayerCard';
import cls from './ImageService.module.scss';

export const ImageService = () => {
  const [data, setData] = useState();
  const userToken = AuthService.getCurrentUser()?.token;

  useEffect(() => {
    fetchCards();
  }, []);

  async function fetchCards() {
    const { data } = await axios.get(`${BACKEND_URL}/nft/nft/all/`, {
      params: {
        page: 7,
      },
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    setData(data);
    console.log(data);
  }

  return (
    <div className="ml-[200px]">
      <h2>Service</h2>
      {data && data.results.map((item) => (
        <PlayerCard card={item} />
      ))}
    </div>
  );
};
