import { createAsyncThunk } from "@reduxjs/toolkit";
import { $api } from "@utils/api";

export const recovery = createAsyncThunk('auth/recovery', async (_, thunkAPI) => {
  const {username, country_code, form_type} = thunkAPI.getState().auth;
  try { 
    const {data} = await $api.post('/account/send_code/ordinary/', form_type === 'email' ? {
      email: username,
      form_type,
    } : {
      country_code,
      form_type,
      phone: username
    });
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('An error occurred while sending the request')
  }
})

export const updatePassword = createAsyncThunk('auth/updatePassword', async (_, thunkAPI) => {
  const { password, form_type, country_code, username } = thunkAPI.getState().auth;
  try {
    const { data } = await $api.post('/account/update_password/', form_type === 'email' ? {
      new_password: password,
      old_password: '',
      recipient: username
    } : {
      new_password: password,
      old_password: '',
      recipient: username,
      country_code
    });
    localStorage.setItem('user', JSON.stringify(data));
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue('An error occurred while sending the request')
  }
})