export const mockTeams = [
    {
        "id": 3762,
        "name": "The Best",
        "players": [
            {
                "id": 3996,
                "image_card": "/media/nft_image_card/image_ULNVt5P.jpg",
                "trauma": false,
                "player_type": "ОДИНОЧНАЯ МУЖСКАЯ"
            },
            {
                "id": 4290,
                "image_card": "/media/nft_image_card/image_kCcgBWG.jpg",
                "trauma": false,
                "player_type": "ОДИНОЧНАЯ ЖЕНСКАЯ"
            },
            {
                "id": 20,
                "image_card": "/media/nft_image_card/image_pLXx5Bi.jpg",
                "trauma": false,
                "player_type": "ПАРНАЯ ЖЕНСКАЯ"
            },
            {
                "id": 21,
                "image_card": "/media/nft_image_card/image_DKuLS4i.jpg",
                "trauma": false,
                "player_type": "ПАРНАЯ ЖЕНСКАЯ"
            },
            {
                "id": 22,
                "image_card": "/media/nft_image_card/image_wwzarUO.jpg",
                "trauma": false,
                "player_type": "ПАРНАЯ МУЖСКАЯ"
            },
            {
                "id": 23,
                "image_card": "/media/nft_image_card/image_jPEzbiv.jpg",
                "trauma": false,
                "player_type": "ПАРНАЯ ЖЕНСКАЯ"
            },
            {
                "id": 4309,
                "image_card": "/media/nft_image_card/image_40Ktgop.jpg",
                "trauma": false,
                "player_type": "ОДИНОЧНАЯ МУЖСКАЯ"
            },
            {
                "id": 26,
                "image_card": "/media/nft_image_card/image_kCcgBWG.jpg",
                "trauma": false,
                "player_type": "ОДИНОЧНАЯ ЖЕНСКАЯ"
            }
        ],
        "average_player_rating": 55.5,
        "crew": [],
        "tournament": [],
        "updated_at": "2025-02-07T12:50:37.764129+03:00"
    }
]