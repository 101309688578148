import { memo, Suspense, useMemo } from 'react';
import {
  Link, Navigate, Route, Routes, useLocation,
  useParams,
} from 'react-router-dom';
import classNames from 'classnames';
import { Profile } from '@services/profile.service';
import { Tabs } from '../../../../shared/Tabs/Tabs';
import { profileTabs } from '../model/profile';
import cls from './ProfileContent.module.scss';
import { EditProfileForm } from '../../EditProfileForm';
import { ProfileTransactions } from '../../ProfileTransactions';
import { ProfileTeams } from '../../ProfileTeams';
import { useSelector } from 'react-redux';

interface ProfileContentProps {
  user: Profile;
  className?: string;
}

export const ProfileContent = memo((props: ProfileContentProps) => {
  const { user, className } = props;
  const { pathname } = useLocation();
  const { profileId } = useParams<{ profileId: string }>();
  const currentUserId = useSelector((state) => state.auth.user?.id)

  const isCurrentUserProfile = useMemo(() => (
    Number(profileId) === currentUserId
  ), [currentUserId, profileId]);

  return (
    <div className={classNames(cls.ProfileContent, className)}>
      <div className={cls.headerBlock}>
        {isCurrentUserProfile ? (
          <ul className={cls.tabs}>
            {profileTabs.map((item, index) => (
              <li
                className={classNames(cls.tabItem,
                  pathname.includes(item.href.slice(1)) && cls.active)}
                key={`${index}_${item.href}`}
              >
                <Link className={cls.link} to={item.href}>
                  {item.children}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <div className={classNames(cls.tabItem, cls.active)}>
            <a href='#' className={cls.link}>
              Команды
            </a>
          </div>
        )}
      </div>
      {isCurrentUserProfile ? (
        <Suspense fallback="">
          <Routes>
            <Route path="info" element={<ProfileTeams notViewLoading />} />
            <Route path="personal" element={<EditProfileForm user={user} />} />
            <Route path="transactions" element={<ProfileTransactions />} />
            <Route path="*" element={<Navigate replace to="./info" />} />
          </Routes>
        </Suspense>
      ) : (
        <>
          <ProfileTeams isNotEditable />
          <Navigate to={`/profile/${profileId}/info`} />
        </>
      )}
    </div>
  );
});
