import { memo } from 'react';
import classNames from 'classnames';
import { Tournament } from '@services/tournaments.service';
import cls from './TournamentCard.module.scss';
import { TournamentCardImageBlock } from '../../TournamentCardImageBlock';
import { TournamentPrizePool } from '../../TournamentPrizePool';
import { Button } from '@newComponents/shared/Button/Button';
import { Link } from 'react-router-dom';

interface TournamentCardProps {
  isInstruction: boolean;
  tournament: Tournament;
  className?: string
}

export const TournamentCard = memo((props: TournamentCardProps) => {
  const { isInstruction, tournament, className } = props;

  return (
    <div className={classNames(cls.TournamentCard, className)}>
      <div className={cls.imageBlock}>
        <TournamentCardImageBlock tournament={tournament} />
      </div>
      <div className={cls.mainContent}>
        <div className={cls.blockContent}>
          <div className={cls.textInfo}>
            <h3 className={cls.title}>
              Rules
            </h3>
            <p className={cls.description}>
              Pick your best five using players from any club or league and win Sorare Pro Cards! Create the best team to dominate the international break and take home Star rewards!
            </p>
          </div>
          <ul className={cls.mainContentList}>
            <li className={cls.mainContentItem}>
              <h3 className={cls.mainContentItemName}>
                Покрытие
              </h3>
              <h3 className={cls.mainContentItemValue}>
                {tournament.cover}
              </h3>
            </li>
            <li className={cls.mainContentItem}>
              <h3 className={cls.mainContentItemName}>
                Участники
              </h3>
              <h3 className={cls.mainContentItemValue}>
                {tournament.participants}
              </h3>
            </li>
            <li className={cls.mainContentItem}>
              <h3 className={cls.mainContentItemName}>
                Место
              </h3>
              <h3 className={cls.mainContentItemValue}>
                {tournament.place}
              </h3>
            </li>
          </ul>
        </div>
      </div>
      <div className={cls.prize}>
        <div className={cls.blockContent}>
          <TournamentPrizePool className={cls.prizePool} />
          {!tournament.is_participate ? (
            <Link
              to={isInstruction ?
                '/instruction/change-team' :
                `/tournaments/${tournament.id}/change-team`
              }
            >
              <Button className={cls.button} theme='contained-green'>
                Participate in the tournament ${tournament.price}
              </Button>
            </Link>
          ) : (
            <Link to={`/tournaments/${tournament.id}`}>
              <Button
                className={cls.button} theme='contained-grey'
              >
                Go to the tournament
              </Button>
            </Link>
          )}
        </div>
      </div>

    </div>
  );
});
