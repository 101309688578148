import { memo, useEffect, useState } from "react";
import classNames from 'classnames';
import cls from './FriendlyMatchLiveInfo.module.scss';
import { Score } from "../../TournamentLiveInfo/ui/TournamentLiveInfo";
import { TournamentLiveStats } from "../../TournamentLiveStats";
import AuthService from "@services/AuthService";

interface FriendlyMatchLiveInfoProps {
  className?: string;
}

export const FriendlyMatchLiveInfo = memo((props: FriendlyMatchLiveInfoProps) => {
  const { className } = props;
  const [score, setScore] = useState<Score>();

  useEffect(() => {
    const socket = new WebSocket(
      `wss://nft-tennis.com/api/match/?token=${AuthService.getCurrentUser().token}`
    );
  
    socket.onopen = () => {
      console.log('Connected to WebSocket server');
    };
  
    socket.onmessage = (event) => {
      setScore(JSON.parse(event.data));
    };
  
    socket.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };
  
    return () => {
      socket.close();
    };
    }, []);

  return (
    <div className={classNames(cls.FriendlyMatchLiveInfo, className)}>
      <div className={cls.liveStatsBlock}>
        <TournamentLiveStats score={score} />
      </div>
    </div>
  )
})