import { FormEvent, memo, useCallback } from "react";
import { Button } from "@newComponents/shared/Button/Button";
import { Link } from 'react-router-dom';
import WechatIcon from '@assets/icons/wechat.svg';
import GoogleIcon from '@assets/icons/google.svg';
import FacebookIcon from '@assets/icons/facebook.svg';
import MetamaskIcon from '@assets/icons/metamask.svg';
import classNames from "classnames";
import cls from './SignUpModalDataEntryForm.module.scss';
import { Input } from "@newComponents/shared/Input/Input";
import { signUp } from "@store/services/signUp";
import { useDispatch, useSelector } from "react-redux";
import { authActions, AuthFields, AuthFormType } from "@store/slices/auth";
import { PhoneInput } from "../../PhoneInput";
import { checkEmail, checkNumber } from "@utils/validate";

function validate(username: string, type: AuthFormType) {
  const errors: AuthFields = {};

  if (type === 'email') {
    const emailError = checkEmail(username);
    if (emailError) {
      errors.username = emailError;
    }
  } else {
    const phoneNumberError = checkNumber(username);
    if (phoneNumberError) {
      errors.username = phoneNumberError;
    }
  }

  return errors
}

interface SignUpModalDataEntryFormProps {
  className?: string;
}

export const SignUpModalDataEntryForm = memo((props: SignUpModalDataEntryFormProps) => {
  const { className } = props;
  const dispatch = useDispatch();
  const formType = useSelector((state) => state.auth.form_type)
  const authError = useSelector((state) => state.auth.authError)
  const authLoading = useSelector((state) => state.auth.isLoading)
  const username = useSelector(state => state.auth.username)
  const usernameError = useSelector((state) => state.auth?.validateErrors.username)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const errors = validate(username, formType);
    console.log(errors)
    if (Object.keys(errors).length > 0) {
      dispatch(authActions.setValidateErrors(errors));
    } else {
      dispatch(signUp());
    }
  };

  const handleChangeUsername = useCallback(async (value: string) => {
    dispatch(authActions.setUsername(value));
  }, [])

  const handleChangeCountryCode = useCallback((countryCode: string) => {
    dispatch(authActions.setCountryCode(countryCode));
  }, [])

  console.log(authLoading)

  return (
    <div className={classNames(cls.SignUpModalDataEntryForm, className)}>
      <form className={cls.form} onSubmit={handleSubmit}>
        {formType === 'phone' ? (
          <PhoneInput
            className={cls.input}
            placeholder="9892329898"
            onChange={handleChangeUsername}
            onChangeCountryCode={handleChangeCountryCode}
            error={authError || usernameError}
          />
        ) : (
          <Input
            className={cls.input}
            placeholder="example@mail.com"
            theme="dark_grey"
            onChange={handleChangeUsername}
            error={authError || usernameError}
          />
        )}
        <Button
          type="submit"
          className={cls.button}
          theme="contained-green"
          disabled={authLoading}
        >
          Go next
        </Button>
      </form>
      <div className={cls.lineBlock}>
        <div className={cls.line} />
        <span>or</span>
        <div className={cls.line} />
      </div>
      <ul className={cls.oauthList}>
        <li className={classNames(cls.oauthItem)}>
          <Link className={classNames(cls.oauthLink, cls.wechatLink)} to="#">
            <WechatIcon />
          </Link>
        </li>
        <li className={classNames(cls.oauthItem)}>
          <Link className={classNames(cls.oauthLink, cls.googleLink)} to="#">
            <GoogleIcon />
          </Link>
        </li>
        <li className={classNames(cls.oauthItem)}>
          <Link className={classNames(cls.oauthLink, cls.facebookLink)} to="#">
            <FacebookIcon />
          </Link>
        </li>
      </ul>
      <Link className={cls.metamaskLink} to="#">
        <MetamaskIcon />
        <span>Войти с помощью MetaMask</span>
      </Link>
    </div>
  )
})