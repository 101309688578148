import { memo } from "react";
import classNames from 'classnames'
import cls from './AuthChangeType.module.scss';
import { Button } from "@newComponents/shared/Button/Button";
import { useSelector } from "react-redux";
import { useChangeAuthType } from "../../hooks/useChangeAuthType";

interface AuthChangeTypeProps {
  className?: string;
}

export const AuthChangeType = memo((props: AuthChangeTypeProps) => {
  const { className } = props;
  const formType = useSelector((state) => state.auth.form_type);
  const {
    handleChangeEmailType,
    handleChangePhoneType
  } = useChangeAuthType();

  return (
    <div className={classNames(cls.AuthChangeType, className)}>
      <Button
        className={classNames(cls.controlButton, formType === 'email' && cls.active)}
        theme="clear"
        onClick={handleChangeEmailType}
        isHovered={false}
      >
        E-mail
      </Button>
      <Button
        className={classNames(cls.controlButton, formType === 'phone' && cls.active)}
        theme="clear"
        onClick={handleChangePhoneType}
        isHovered={false}
      >
        Phone number
      </Button>
    </div>
  )
})