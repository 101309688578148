import { memo } from "react";
import classNames from 'classnames'
import cls from './SearchFriends.module.scss';

interface SearchFriendsProps {
  className?: string;
}

export const SearchFriends = memo((props: SearchFriendsProps) => {
  const { className } = props;

  return (
    <div className={classNames(cls.SearchFriends, className)}>
      
    </div>
  )
})