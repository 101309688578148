import { Modal } from '@newComponents/shared/Modal/Modal';
import { useFetchUserFriendsQuery } from '@services/users.service';
import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames'
import cls from './TournamentsFriendsListModal.module.scss';
import { Avatar } from '@newComponents/shared/Avatar/Avatar';
import { Button } from '@newComponents/shared/Button/Button';
import { useInviteUserOnFriendlyMatchMutation } from '@services/tournaments.service';
import { BACKEND_BASE_URL } from '@constants/constants';
import toast from 'react-hot-toast';
import { SearchUserCard } from '@newComponents/ui/SearchUserCard';
import { useFetchTeamsQuery } from '@services/cards.service';

interface TournamentsFriendsListModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
}

export const TournamentsFriendsListModal = memo((props: TournamentsFriendsListModalProps) => {
  const { isOpen, onClose, className } = props;
  const currentUserId = useSelector((state) => state.auth?.user?.id);
  const {data: currentUserFriends} = useFetchUserFriendsQuery(currentUserId);
  const {data: currentUserTeams} = useFetchTeamsQuery(currentUserId);
  const [inviteUser, {error}] = useInviteUserOnFriendlyMatchMutation();
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedTeamId, setSelectedTeamId] = useState<number>();

  const handleSelectUser = useCallback((userId: number) => {
    setSelectedUserId(userId)
  }, []);

  const handleSelectTeam = useCallback((teamId: number) => {
    setSelectedTeamId(teamId);
  }, [])

  const handleInviteUserOnFriendlyMatch = useCallback(async () => {
    try {
      await inviteUser({
        teamId: selectedTeamId,
        userId: selectedUserId
      }).unwrap();
      onClose();
    } catch (error) {
      console.log(error)
    }
  }, [inviteUser, selectedTeamId, selectedUserId]);

  useEffect(() => {
    if (currentUserTeams && currentUserTeams.length === 1) {
      setSelectedTeamId(currentUserTeams[0].id)
    }
  }, [currentUserTeams]);

  return (
    <Modal
      contentClassName={classNames(cls.TournamentsFriendsListModal, className)}
      lazy
      onClose={onClose}
      isOpen={isOpen}
      theme="grey_opacity"
    >
      <h3 className={cls.title}>
        Friendly match
      </h3>
      <p className={cls.description}>
        Choose an opponent from your friends list and challenge him!
      </p>
      <ul className={cls.friendsList}>
        {currentUserFriends && currentUserFriends.map((user) => (
          <li
            className={classNames(cls.item)}
            key={user.id}
          >
            <SearchUserCard
              isSelected={selectedUserId === user.id}
              nickname={user.nickname}
              avatar={user.avatar}
              onClick={() => handleSelectUser(user.id)}
            />
          </li>
        ))}
      </ul>
      <ul className={cls.teams}>
        {currentUserTeams && currentUserTeams.map((team) => (
          <li
            className={classNames(
              cls.item,
              cls.teamItem,
              selectedTeamId === team.id && cls.active
            )}
            key={team.id}
            onClick={() => handleSelectTeam(team.id)}
          >
            <h3 className={cls.teamName}>
              {team.name}
            </h3>
            <ul className={cls.teamPlayers}>
              {team.players.map((player) => (
                <li key={player.id}>
                  <img
                    className={cls.teamPlayerImage}
                    src={BACKEND_BASE_URL + player.image_card}
                    alt=""
                  />
                </li>
              ))}
            </ul>
          </li>
        ))}
        {error && (
          <p className={cls.error}>
            This user cannot create a friendly match because he is already participating in a tournament or friendly match
          </p>
        )}
      </ul>
      <Button
        className={cls.button}
        theme="contained-green"
        disabled={!selectedTeamId || !selectedUserId}
        onClick={handleInviteUserOnFriendlyMatch}
      >
        Call for a match
      </Button>
    </Modal>
  )
})